import React from "react";

const ButtonGrey = ({
  type = "button",
  style,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      style={style}
      disabled={disabled}
      className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-[#E0E0E0]
      py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-black
            font-medium leading-6 text-[12px] md:text-[16px] hover:bg-[#f1f1f1] transition-all disabled:bg-[#c7c7c7]
             disabled:text-black disabled:opacity-70 disabled:cursor-not-allowed duration-200 cursor-pointer
             xs:min-w-[80px] xs:py-[7px] xs:px-[14px]   ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonGrey;
