import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import SaudiSymbol from "../../components/currency/SaudiSymbol";
const QuantityInput = ({
  htmlFor,
  labelTitle,
  labelPrice,
  quantity,
  labelStyles,
  onQuantityChange,
  ageInfo,
  max,
  disabled,
  priceBefore,
}) => {
  console.log("🚀 ~ labelPrice:", labelPrice);
  const { translate } = useLanguage();
  console.log("🚀 ~ priceBefore:", priceBefore);
  // console.log("🚀 ~ disabled:", disabled)
  const handleIncrement = () => {
    if (max !== undefined && quantity >= max) {
      return; // Prevent incrementing if quantity has reached the max value
    }
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      onQuantityChange(quantity - 1);
    }
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <div className="w-full  xs:gap-[0.4rem] flex items-start justify-between gap-2 md:gap-[1.8rem]">
        <label
          htmlFor={htmlFor}
          style={{
            gap: "4px",
          }}
          className={`text-black flex flex-col items-start justify-start  text-[12px] xs:text-[10px] md:text-[16px] font-normal leading-2   ${labelStyles}`}
        >
          <div className="w-full flex items-center md:items-start justify-start gap-2 ">
            <span className="flex flex-col item-start justify-start gap-1">
              <span> {labelTitle}</span>
            </span>
             <span className="self-start">-</span>

            {labelPrice !== undefined && (
              <span className="self-start flex">
                {/* If both priceBefore and labelPrice are 0, show "Free" */}
                {priceBefore === 0 && labelPrice === 0 ? (
                  <span className="font-medium leading-6">Free</span>
                ) : (
                  <>
                    {/* Show priceBefore if it's greater than labelPrice */}
                    {priceBefore > labelPrice && (
                      <>
                      <span className="flex gap-2 line-through text-[#EB5757] text-[12px] md:text-[14px]">
                        {formatNumberWithCommas(priceBefore)}  <SaudiSymbol fillColor="#EB5757" />
                      </span>
                      <span className="mx-[6px]">/</span>
                      </>
                    )}
                    {/* Only show labelPrice if it's greater than 0 */}
                    {labelPrice > 0 && (
                      <span className="flex gap-2 font-medium leading-6 text-[12px] md:text-[14px]">
                        {`${formatNumberWithCommas(labelPrice)} `}
                        <SaudiSymbol />
                      </span>
                    )}
                  </>
                )}
              </span>
            )}
          </div>
          <span className="text-[12px] text-gray-500"> {ageInfo}</span>
        </label>
        <div
          className={` kn-quantity-input-container  w-[110px] xs:w-[85px]  md:w-[150px] lg:w-[175px] h-[45px] xs:h-[35px]`}
        >
          <button
            onClick={handleDecrement}
            className={`text-[#9A9A9A] disabled:cursor-not-allowed w-[3rem] text-[22px] font-medium `}
            disabled={disabled}
          >
            -
          </button>
          <input
            className="text-black xs:w-[1rem]  w-[3rem] text-center  disabled:cursor-none bg-transparent border-none"
            type="number"
            value={quantity}
            onChange={(e) => onQuantityChange(parseInt(e.target.value, 10))}
            name="Quantity input"
            id={htmlFor}
            max={max}
            disabled={true}
          />
          <button
            disabled={disabled}
            onClick={handleIncrement}
            className={`${
              disabled ? "text-[#9A9A9A]" : "text-[#FCD12A]"
            }   w-[3rem] text-[22px] disabled:cursor-not-allowed  font-medium ${
              max !== undefined && quantity >= max
                ? "cursor-not-allowed opacity-50 text-[#9A9A9A]"
                : ""
            } ${
              max !== undefined && quantity >= max ? "cursor-not-allowed " : ""
            }`}
          >
            +
          </button>
        </div>
      </div>
    </>
  );
};

export default QuantityInput;
