import React from "react";

const MinusAccordion = () => {
  return (
    <div className="w-[28px] h-[28px] bg-[#FCD12A] rounded-[8px] flex items-center justify-center p-2">
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="256"
      viewBox="0 0 256 256"
    >
      <g fill="#fff" strokeMiterlimit="10" strokeWidth="0">
        <path
          d="M86.5 48.5h-83a3.5 3.5 0 110-7h83a3.5 3.5 0 110 7z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
        ></path>
        <path
          d="M86.5 48.5h-83a3.5 3.5 0 110-7h83a3.5 3.5 0 110 7z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
        ></path>
      </g>
    </svg>
    </div>
  );
};

export default MinusAccordion;
