// axiosInstance.js
import axios from "axios";
import { useUser } from "../contexts/UserContext";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("🚀 ~ REACT_APP_API_BASE_URL:", REACT_APP_API_BASE_URL)

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

// Add a request interceptor to axiosInstance
axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const bookingDetailsToken=localStorage.getItem("bookingDetailsToken")
    console.log("🚀 ~ bookingDetailsToken:", bookingDetailsToken)
    // If token is present, set the Authorization header
    if (user && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } 
    else if(bookingDetailsToken){
      config.headers["Authorization"] = `Bearer ${bookingDetailsToken}`;
    }
    else {
      // don't send authorization bearer token
      delete config.headers["Authorization"];
    }
    config.__retryCount = config.__retryCount || 0; // Initialize retry count if not already set
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors with retry logic
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;

    // Check if it's a 401 error and retry limit hasn't been reached
    if (response?.status === 401 && config.__retryCount < 3) {
      config.__retryCount += 1; // Increment retry count

      const token = localStorage.getItem("token");

      if (token) {
        // If token exists, retry the request with the new token
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
        return axiosInstance(config); // retry the request
      } else {
        // If no token, log out the user and redirect to login
        const { logout, goToLogin } = useUser(); // Access logout and redirect from context
        logout();
        goToLogin();
      }
    }

    return Promise.reject(error); // Reject if max retries reached or not 401
  }
);

export default axiosInstance;
