import React, { useState, useEffect,useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GuestBookingInfo from "./GuestBookingInfo";
import pic from "../../../assets/beach-access/beach-access-grid-1.webp";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { useBooking } from "../../../contexts/BookingContext";
import { BookingInfoSummary } from "./BookingInfoSummary";
import { DependentBookingInfo } from "./DependentBookingInfo";
import axiosInstance from "../../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import { useLanguage } from "../../../contexts/LanguageContext";
import { notification } from "antd";
import { useBookingTracking } from "../../../contexts/BookingTrackingContext";
import { useUser } from "../../../contexts/UserContext";
import { Tooltip } from "antd";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import PlusAccordion from "../../../shared/faq/PlusAccordion";
import MinusAccordion from "../../../shared/faq/MinusAccordion";
import BlurFade from "../../../components/ui/blur-fade";
import manIcon from "../../../assets/manIconGuest.svg";
import manIconActive from "../../../assets/manIconActive.svg";
import womanIcon from "../../../assets/womenIconGuest.svg";
import womanIconActive from "../../../assets/womenIconActive.svg";
import juniorIcon from "../../../assets/juniorIconGuest.svg";
import juniorIconActive from "../../../assets/juniorIconActive.svg";
import kidIcon from "../../../assets/kidIconGuest.svg";
import kidIconActive from "../../../assets/kidIconActive.svg";
import { icons } from "antd/es/image/PreviewGroup";
import { useTransition, animated } from "@react-spring/web";
import { useOutletContext } from "react-router-dom";

const BookingInformation = React.memo(() => {
  const { globalData } = useOutletContext();

  const location = useLocation();
  const navigate = useNavigate();
  const { user, userType } = useUser();

  // Get the static page tracking info
  const { currentPage, previousPage } = useBookingTracking();

  const [api, contextHolder] = notification.useNotification();
  const { language, translate } = useLanguage();
  const { state } = location;
  const {
    booking,
    addManToBooking,
    addWomanToBooking,
    addJuniorToBooking,
    addKidToBooking,
    setBooking,
    isWomanDay,
  } = useBooking();

  const [guest, setGuest] = useState();
  const [dependent, setDependent] = useState();
  const [activities, setActivities] = useState([]);
  const [food, setFood] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [guestPricePerPerson, setGuestPricePerPerson] = useState(null);
  const [dependentPricePerPerson, setDependentPricePerPerson] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const [unselectedMembers, setUnselectedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const maxMenInBooking = globalData?.max_men_in_booking;
  const maxWomenInBooking = globalData?.max_women_in_booking;

  // Calculate current counts of men and women in booking_users
  const menCount = booking.booking_users.filter(
    (user) => user.gender === "male"
  ).length;
  const womenCount = booking.booking_users.filter(
    (user) => user.gender === "female"
  ).length;
  const juniorCount = booking.booking_dependents.filter(
    (dependent) => dependent.member_type === "junior"
  ).length;
  const kidCount = booking.booking_dependents.filter(
    (dependent) => dependent.member_type === "kid"
  ).length;


  useLayoutEffect(() => {
  // wanna scroll to the top of the page when the page loads
  window.scrollTo(0, 0);
  }, []);

  // useEffect if no booking.booking_date here navigate to booking
  useEffect(() => {
    if (!booking.booking_date) {
      navigate("/booking");
    }
  }, [booking.booking_date]);

  useEffect(() => {
    if (state) {
      setGuestPricePerPerson(state.guestPrice);
      setDependentPricePerPerson(state.dependentPrice);
    }
  }, []);

  useEffect(() => {
    const savedBooking = JSON.parse(localStorage.getItem("bookings")) || {};

    // Use a fallback key if user.id is not available
    const bookingKey = user?.id || "kona_currentBooking";

    if (savedBooking[bookingKey]) {
      setBooking(savedBooking[bookingKey]);
      setSelectedMembers(savedBooking[bookingKey].booking_users || []);
    }
  }, [user]);

  // Save the booking object to local storage on any booking changes
  useEffect(() => {
    const savedBooking = JSON.parse(localStorage.getItem("bookings")) || {};

    // Use a fallback key if user.id is not available
    const bookingKey = user?.id || "kona_currentBooking";

    // Ensure bookingKey is valid and not undefined
    if (bookingKey && bookingKey !== "undefined") {
      savedBooking[bookingKey] = booking; // Save booking object with the valid key
      localStorage.setItem("bookings", JSON.stringify(savedBooking));
    }
  }, [booking, user]);

  // wanna when this page loads for the first time to clear formErrors from local storage
  useEffect(() => {
    localStorage.removeItem("formErrors");
  }, []);
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  useEffect(() => {
    if (booking) {
      const newGuestCount = booking.booking_users.length;
      const newDependentCount = booking.booking_dependents.length;

      // Only update state if counts have actually changed
      if (guest !== newGuestCount) {
        setGuest(newGuestCount);
      }
      if (dependent !== newDependentCount) {
        setDependent(newDependentCount);
      }
    }
  }, [booking]);

  // fetch activities
  useEffect(() => {
    if (booking.booking_date) {
      const formattedDate = new Date(booking.booking_date)
        .toISOString()
        .split("T")[0];

      const getActivities = async () => {
        setIsLoading(true);
        try {
          const activitiesData = await axiosInstance.get(
            `/booking/pre-requests/data/activities-list?date=${formattedDate}&user_category_id=${booking.user_category_id}`
          );
          if (activitiesData.status === 200) {
            setActivities(activitiesData.data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          document.body.style.overflow = "auto";
        }
      };

      getActivities();
    }
  }, [booking.booking_date, booking.user_category_id]);

  // fetch Food
  useEffect(() => {
    if (booking.booking_date) {
      const formattedDate = new Date(booking.booking_date)
        .toISOString()
        .split("T")[0];

      const getFood = async () => {
        setIsLoading(true);
        try {
          const foodList = await axiosInstance.get(
            `/booking/pre-requests/data/food-category-list?date=${formattedDate}&user_category_id=${booking.user_category_id}`
          );
          if (foodList.status === 200) {
            setFood(foodList.data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          document.body.style.overflow = "auto";
        }
      };

      getFood();
    }
  }, [booking.booking_date, booking.user_category_id]);

  useEffect(() => {
    if (booking && booking.booking_users) {
      setSelectedMembers(booking.booking_users); // Populate selected members from booking object
    }
  }, [booking]);

  const handleBack = () => {
    if (previousPage) {
      navigate(previousPage);
    } else {
      navigate(-1);
    }
  };

  const proceedToPaymentHandler = async () => {
    try {
      // First validate users
      const validateUsersResponse = await axiosInstance.post(
        `/booking/validate-users?lang=${language}`,
        {
          booking_date: booking?.booking_date,
          booking_users: booking?.booking_users,
          user_category_id: booking?.user_category_id,
          bundle_ticket_id: booking?.bundle_ticket_id,
          booking_dependents: booking?.booking_dependents,
        }
      );

      // Check if validate-users call is successful
      if (validateUsersResponse.status === 200) {
        // Then validate before creating the booking
        const validateBeforeCreate = await axiosInstance.post(
          `/booking/validate-before-create-booking?lang=${language}`,
          {
            booking_platform: "Web",
            booking_date: booking?.booking_date,
            booking_users: booking?.booking_users,
            user_category_id: booking?.user_category_id,
            bundle_ticket_id: booking?.bundle_ticket_id,
            booking_dependents: booking?.booking_dependents,
          }
        );

        // Check if need_accept is true
        if (validateBeforeCreate.data?.data?.need_accept) {
          navigate("/pending-booking");
          return; // Exit the function early
        }

        // If need_accept is false, navigate to payment
        navigate("/payment");
      }
    } catch (error) {
      console.error(error);

      setFormErrors(error?.response?.data?.errors || {});

      // set form errors at local storage
      localStorage.setItem(
        "formErrors",
        JSON.stringify(error?.response?.data?.errors)
      );

      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  const [expanded, setExpanded] = useState([]); // Start with an empty array if no items are expanded

  const handleChange = (expandedItems) => {
    setExpanded(expandedItems); // Update the expanded state with the selected AccordionItem uuid
  };

  const [selectedGuestTypes, setSelectedGuestTypes] = useState([]);

  const GUEST_OPTIONS = isWomanDay
    ? [
        {
          id: 2,
          name: {en: "Woman", ar: "امرأة"},
          gender: "female",
          memberType: "daypass",
          quantity: 1,
          icon: womanIcon,
          activeIcon: womanIconActive,
        },
        {
          id: 4,
          name: {en: "Kid", ar: "طفل"},
          gender: null,
          memberType: "kid",
          quantity: 1,
          icon: kidIcon,
          activeIcon: kidIconActive,
        },
      ]
    : [
        {
          id: 1,
          name: {en: "Man", ar: "رجل"},
          gender: "male",
          memberType: "daypass",
          quantity: 1,
          icon: manIcon,
          activeIcon: manIconActive,
        },
        {
          id: 2,
          name: {en: "Woman", ar: "امرأة"},
          gender: "female",
          memberType: "daypass",
          quantity: 1,
          icon: womanIcon,
          activeIcon: womanIconActive,
        },
        {
          id: 3,
          name: {en: "Junior", ar: "شاب"},
          gender: null,
          memberType: "junior",
          quantity: 1,
          icon: juniorIcon,
          activeIcon: juniorIconActive,
        },
        {
          id: 4,
          name: {en: "Kid", ar: "طفل"},
          gender: null,
          memberType: "kid",
          quantity: 1,
          icon: kidIcon,
          activeIcon: kidIconActive,
        },
      ];

  const handleGuestTypeSelect = (type) => {
    if (
      (type === "Man" && menCount >= maxMenInBooking) ||
      (type === "Woman" && womenCount >= maxWomenInBooking)
    ) {
      return; // Prevent selecting if max is reached
    }

    setSelectedGuestTypes((prevSelected) =>
      prevSelected.includes(type)
        ? prevSelected.filter((t) => t !== type)
        : [...prevSelected, type]
    );
  };

  const handleAddGuest = () => {
    selectedGuestTypes.forEach((type) => {
      if (type === "Man" && menCount < maxMenInBooking) {
        addManToBooking();
      } else if (type === "Woman" && womenCount < maxWomenInBooking) {
        addWomanToBooking();
      } else if (type === "Junior") {
        addJuniorToBooking();
      } else if (type === "Kid") {
        addKidToBooking();
      }
    });

    // Notify user
    openNotificationWithIcon(
      "success",
      language === "en" ? "Added Successfully" : "تم الاضافه بنجاح"
    );

    // Reset the selection after adding guests
    setSelectedGuestTypes([]);
  };

  return (
    <>
      {contextHolder}

      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="container mx-auto">
            <button
              onClick={handleBack}
              className="flex items-start justify-start gap-[16px] my-[0.5rem] lg:my-[2rem]"
            >
              <svg
                className="self-center rtl:rotate-180 xs:w-[18px] xs:h-[18px]"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
                viewBox="0 0 25 24"
              >
                <path
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
                ></path>
              </svg>

              <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
                {language === "en" ? "Booking Information" : "معلومات الحجز"}
              </span>
            </button>

            <div className="kn-booking-info-grid xs:my-[14px] my-[40px]">
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                {booking.booking_users.map((user, index) => (
                  <GuestBookingInfo
                    user={user}
                    activities={activities}
                    food={food}
                    index={index}
                    date={booking?.booking_date}
                    key={index}
                    type="adult"
                    errors={formErrors}
                  />
                ))}

                {booking.booking_dependents.map((user, index) => (
                  <DependentBookingInfo
                    user={user}
                    activities={activities}
                    food={food}
                    date={booking?.booking_date}
                    index={index}
                    key={index}
                    type="dependent"
                    errors={formErrors}
                  />
                ))}

                {user?.user_type !== "athlete" && state?.selectedBundle?.type !== "Couple" && (
                  <div className=" w-full h-auto ">
                    <div className="kn-guests-grid p-[24px] xs:p-[12px]">
                      <Accordion
                        // Expand the "Payment Summary" accordion by default
                        onChange={handleChange} // Set the handler that updates the state
                        allowZeroExpanded
                      >
                        <AccordionItem uuid={"acc-one"}>
                          <BlurFade yOffset={0} delay={0.13} inView>
                            <AccordionItemHeading className="">
                              <AccordionItemButton className="w-full h-[28px] flex items-center justify-between">
                                <h3 className="text-[#2A2A2A] text-[14px] md:text-[16px]  font-medium leading-7 ">
                                  {language === "en"
                                    ? "Add Another Guest "
                                    : " إضافة ضيف آخر "}
                                </h3>{" "}
                                <AccordionItemState>
                                  {({ expanded }) => (
                                    <div className="flex items-center">
                                      {expanded ? (
                                        <MinusAccordion />
                                      ) : (
                                        <PlusAccordion />
                                      )}
                                    </div>
                                  )}
                                </AccordionItemState>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          </BlurFade>
                          <AccordionItemPanel className="w-full mt-[24px] ">
                            {!state?.maxValues ? (
                              <ul className="w-full flex items-start justify-start gap-4 md:gap-6 flex-wrap mb-8">
                                {GUEST_OPTIONS.map((member) => (
                                  <li
                                    className="flex flex-col items-center justify-center gap-2 relative"
                                    key={member.id}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`member-${member.id}`}
                                      value={member.name.en}
                                      className="hidden peer"
                                      onChange={() =>
                                        handleGuestTypeSelect(member.name.en)
                                      }
                                      checked={selectedGuestTypes.includes(
                                        member.name.en
                                      )}
                                      disabled={
                                        (member.name.en === "Man" &&
                                          menCount >= maxMenInBooking) ||
                                        (member.name.en === "Woman" &&
                                          womenCount >= maxWomenInBooking)
                                      }
                                    />
                                    <Tooltip
                                      title={
                                        member.name.en === "Man" &&
                                        menCount >= maxMenInBooking
                                          ? "Max limit reached for adding men"
                                          : member.name.en === "Woman" &&
                                            womenCount >= maxWomenInBooking
                                          ? "Max limit reached for adding women"
                                          : ""
                                      }
                                      color="red"
                                      placement="top"
                                    >
                                      <label
                                        htmlFor={`member-${member.id}`}
                                        className={`group w-[52px] h-[52px] md:w-[62px] md:h-[62px] border-2 rounded-full flex items-center justify-center relative
            ${
              selectedGuestTypes.includes(member.name.en)
                ? "border-[#FCD12A] text-[#FCD12A] bg-[#FFF7D9]"
                : "border-[#A1A1A1] text-[#A1A1A1] bg-[#B4B4B426] hover:bg-gray-50"
            }
            ${
              (member.name.en === "Man" && menCount >= maxMenInBooking) ||
              (member.name.en === "Woman" && womenCount >= maxWomenInBooking)
                ? "border-red-500 bg-red-100 cursor-not-allowed max-reached"
                : ""
            }
          `}
                                      >
                                        <div className="block text-center">
                                          <div className="w-full h-full flex items-center justify-center uppercase text-[24px] font-medium">
                                            {selectedGuestTypes.includes(
                                              member.name.en
                                            ) ? (
                                              <img
                                                className="w-[26px] h-[26px] md:w-[35px] md:h-[35px]"
                                                src={member.activeIcon}
                                                alt={member.name}
                                              />
                                            ) : (
                                              <img
                                                className="w-[26px] h-[26px] md:w-[35px] md:h-[35px]"
                                                src={member.icon}
                                                alt={member.name}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        {/* Overlay for max limit reached */}
                                        {((member.name.en === "Man" &&
                                          menCount >= maxMenInBooking) ||
                                          (member.name.en === "Woman" &&
                                            womenCount >=
                                              maxWomenInBooking)) && (
                                          <div className="absolute inset-0 flex items-center justify-center rounded-full bg-red-500 bg-opacity-50 text-white text-2xl font-bold">
                                            ✖
                                          </div>
                                        )}
                                      </label>
                                    </Tooltip>
                                    <p
                                      className={`text-[16px] font-normal self-center ${
                                          selectedGuestTypes.includes(member.name.en)
                                          ? "text-[#FCD12A] font-semibold"
                                          : "group-hover:text-gray-50 text-[#292D32]"
                                      }`}
                                    >
                                      {member.name[language].split(" ")[0]}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              state.maxValues.kids > 0 && (
                                <ul className="w-full flex items-start justify-start gap-4 md:gap-6 flex-wrap mb-8">
                                  {GUEST_OPTIONS.filter(
                                    (member) => member.name.en === "Kid"
                                  ).map((member) => (
                                    <li
                                      className="flex flex-col items-center justify-center gap-2 relative"
                                      key={member.id}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`member-${member.id}`}
                                        value={member.name.en}
                                        className="hidden peer"
                                        onChange={() =>
                                          handleGuestTypeSelect(member.name.en)
                                        }
                                        checked={selectedGuestTypes.includes(
                                          member.name.en
                                        )}
                                        disabled={
                                          kidCount >= state.maxValues.kids
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          kidCount >= state.maxValues.kids
                                            ? "Max limit reached for adding kids"
                                            : ""
                                        }
                                        color="red"
                                        placement="top"
                                      >
                                        <label
                                          htmlFor={`member-${member.id}`}
                                          className={`group w-[42px] h-[42px] md:w-[62px] md:h-[62px] border-2 rounded-full flex items-center justify-center relative
                                          ${
                                            selectedGuestTypes.includes(
                                              member.name.en
                                            )
                                              ? "border-[#FCD12A] text-[#FCD12A] bg-[#FFF7D9]"
                                              : "border-[#A1A1A1] text-[#A1A1A1] bg-[#B4B4B426] hover:bg-gray-50"
                                          }
                                          ${
                                            kidCount >= state.maxValues.kids
                                              ? "border-red-500 bg-red-100 cursor-not-allowed max-reached"
                                              : ""
                                          }
                                        `}
                                        >
                                          <div className="block text-center">
                                            <div className="w-full h-full flex items-center justify-center uppercase text-[24px] font-medium">
                                              {selectedGuestTypes.includes(
                                                member.name.en
                                              ) ? (
                                                <img
                                                  className="w-[26px] h-[26px] md:w-[35px] md:h-[35px]"
                                                  src={member.activeIcon}
                                                  alt={member.name.en}
                                                />
                                              ) : (
                                                <img
                                                  className="w-[26px] h-[26px] md:w-[35px] md:h-[35px]"
                                                  src={member.icon}
                                                  alt={member.name.en}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          {kidCount >= state.maxValues.kids && (
                                            <div className="absolute inset-0 flex items-center justify-center rounded-full bg-red-500 bg-opacity-50 text-white text-2xl font-bold">
                                              ✖
                                            </div>
                                          )}
                                        </label>
                                      </Tooltip>
                                      <p
                                        className={`text-[16px] font-normal self-center ${
                                          selectedGuestTypes.includes(
                                            member.name.en
                                          )
                                            ? "text-[#FCD12A] font-semibold"
                                            : "group-hover:text-gray-50 text-[#292D32]"
                                        }`}
                                      >
                                        {member.name[language].split(" ")[0]}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              )
                            )}

                            <ButtonSecondary
                              // if selectedGuestTypes is empty or its length is zero
                              disabled={selectedGuestTypes.length === 0}
                              onClick={handleAddGuest}
                            >
                              {language === "en" ? "Add Guest" : "إضافة ضيف"}
                            </ButtonSecondary>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full h-full">
                <div className="payment-summary-container">
                  <BookingInfoSummary
                    data={{
                      activity_image: pic,
                      activity_name: "Aqua Park Ticket",
                    }}
                  />
                </div>
              </div>

              {/* Procceed */}
              <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
                <ButtonSecondary
                  onClick={proceedToPaymentHandler}
                  className={`w-full xs:w-[200px] md:w-[250px] lg:w-[300px]`}
                >
                  {language === "en" ? "Proceed To Payment" : "المتابعة للدفع"}
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default BookingInformation;
