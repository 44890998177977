import React, { createContext, useState, useContext, useEffect } from "react";
import en from "../en.json";
import ar from "../ar.json";

// Create a context for the current language and direction
const LanguageContext = createContext();

// Mapping to associate language codes with their respective translation files
const translations = { en, ar };
// Create a provider component
export const LanguageProvider = ({ children }) => {
  // State to hold the current language, defaulting to 'en'
  // const [language, setLanguage] = useState('en');
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("language") || "en";
  });
  // State to hold the current direction, defaulting to 'ltr'
  const [direction, setDirection] = useState(language === "ar" ? "rtl" : "ltr");

  // console.log('language', localStorage.getItem('language'))

  useEffect(() => {
    const newDirection = language === "ar" ? "rtl" : "ltr";
    setDirection(newDirection);
    document.body.dir = newDirection;

    // Store language in local storage
    localStorage.setItem("language", language);
  }, [language]);
  // Retrieve language from local storage on initial load
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  // The translate function takes a key and returns the translation
  // from the current language's JSON, defaulting to the key itself if not found
  const translate = (key) => {
    const currentTranslations = translations[language];
    return currentTranslations[key] || key;
  };
  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, direction, translate }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

// Hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
