import React, { useState, useEffect } from "react";
import { Button, message, Upload } from "antd";
import "react-tabs/style/react-tabs.css";
import AccountInfoItem from "./AccountInfoItem";
import FullNameModal from "./modals/fullNameModal";
import EmailModal from "./modals/EmailModal";
import PhoneModal from "./modals/PhoneModal";
import PasswordModal from "./modals/PassowrdModal";
import DateOfBirthModal from "./modals/DateOfBirthModal";
import IdNumberModal from "./modals/IdNumberModal";
import { useUser } from "../../../contexts/UserContext";
import { useLanguage } from "../../../contexts/LanguageContext";
import BlurFade from "../../ui/blur-fade";
import axiosInstance from "../../../../src/axios/axiosInstance";
import { useOutletContext } from "react-router-dom";

const AccountSettingsInfo = () => {
  const { userProfile } = useOutletContext();
  console.log("🚀 ~ AccountSettingsInfo ~ userProfile:", userProfile);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [picture, setPicture] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { user, token } = useUser();
  console.log("🚀 ~ AccountSettingsInfo ~ token:", token);
  const userInitials = user
    ? user.name
        .split(" ")
        .map((n) => n[0])
        .join("")
    : "";

  const { language } = useLanguage();

  console.log("api,", process.env.REACT_APP_API_BASE_URL);

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("picture", file);
    console.log("🚀 ~ handleFileUpload ~ formData:", formData);

    try {
      const response = await axiosInstance.post(
        "/user/profile",
        {
          picture: file,
          first_name: user?.first_name,
          last_name: user?.last_name,
          phone_code: user?.phone_code,
          phone: user?.phone,
          birthdate: user?.birthdate,
          email: user?.email,
          identity_card_number: user?.identity_card_number,
          identity_card_type: user?.identity_card_type,
          lang: language,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("🚀 ~ handleFileUpload ~ response:", response);

      if (response.status === 200) {
        setUploadedImageFile(file);
        setPicture(file);
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
        message.success("Profile picture updated successfully!");
      } else {
        message.error("Failed to upload profile picture.");
      }
    } catch (error) {
      message.error("Error uploading image. Please try again.");
      console.error("Upload Error:", error);
    }
  };

  const props = {
    name: "picture",
    accept: ".png, .jpg, .jpeg, .webp",
    beforeUpload: (file) => {
      handleFileUpload(file); // Manually handle file upload
      return false; // Prevent default upload behavior
    },
  };

  const labels = {
    en: {
      fullName: "Full Name",
      email: "Email",
      phoneNumber: "Phone Number",
      dateOfBirth: "Date of Birth",
      password: "Password",
      idInformation: "ID Information",
    },
    ar: {
      fullName: "الاسم الكامل",
      email: "البريد الإلكتروني",
      phoneNumber: "رقم الهاتف",
      dateOfBirth: "تاريخ الميلاد",
      password: "كلمة السر",
      idInformation: "معلومات الهوية",
    },
  };

  const accountInfoData = [
    {
      accLabel: labels[language].fullName,
      accValue: user?.name,
      Modal: FullNameModal,
    },
    {
      accLabel: labels[language].email,
      accValue: user?.email,
      Modal: EmailModal,
    },
    {
      accLabel: labels[language].phoneNumber,
      accValue: user?.phone_code + " " + user?.phone,
      Modal: PhoneModal,
    },
    {
      accLabel: labels[language].dateOfBirth,
      accValue: user?.birthdate,
      Modal: DateOfBirthModal,
    },
    {
      accLabel: labels[language].password,
      accValue: "•••••••••••••••••",
      Modal: PasswordModal,
    },
    {
      accLabel: labels[language].idInformation,
      accValue: user?.identity_card_number
        ? "•".repeat(user.identity_card_number.length)
        : "",
      Modal: IdNumberModal,
    },
  ];
  console.log("picture", picture);

  useEffect(() => {
    // Function to revoke the URL when component unmounts or URL changes
    const revokeUrl = () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };

    // Call revokeUrl when component unmounts or URL changes
    return () => revokeUrl();
  }, [previewUrl]);

  return (
    <>
      <section className="w-full h-auto">
        <BlurFade delay={0.06} inView>
          <h1 className="text-[#2A2A2A] text-[28px] font-semibold leading-7">
            {language === "en" ? "Account Settings" : "إعدادات الحساب"}
          </h1>
        </BlurFade>

        <BlurFade delay={0.08} inView>
          <form className="account-setting-photo-upload w-full my-[2rem] flex items-start justify-start gap-3">
            {userProfile?.picture ? (
              <div className="w-[65px] h-[65px] rounded-full overflow-hidden flex items-center justify-center border border-[#FCD12A] bg-[#fcd22a27]">
                <img
                  src={userProfile?.picture}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-[65px] h-[65px] rounded-full overflow-hidden flex items-center justify-center border border-[#FCD12A] bg-[#fcd22a27]">
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <span className="text-[#FCD12A] font-medium text-[24px]">
                    {userInitials}
                  </span>
                )}
              </div>
            )}

            <Upload className="self-center " {...props}>
              <Button className="bg-[#ECECEC] py-2 text-black font-medium text-[14px] h-auto">
                {language === "en"
                  ? " Edit Profile Photo"
                  : "تعديل الصوره الشخصيه"}
              </Button>
            </Upload>
          </form>
        </BlurFade>

        <div className="w-full h-auto rounded-[8px] border border-[#ECECEC] p-4">
          <BlurFade delay={0.1} inView>
            <h3 className="text-[#000000] text-[16px] leading-6 font-semibold mt-2 mb-5">
              {language === "en" ? "Personal Information" : "معلومات شخصية"}
            </h3>
          </BlurFade>

          {/* Rendering AccountInfoItem dynamically */}
          <div className="account-info-list">
            {accountInfoData.map((info, index) => (
              <BlurFade
                className={`account-setting-info-item w-full`}
                delay={index * 0.04}
                inView
                key={index}
              >
                <AccountInfoItem
                  accLabel={info.accLabel}
                  accValue={info.accValue}
                  Modal={info.Modal}
                />
              </BlurFade>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountSettingsInfo;
