import "./App.css";
import { useState, useEffect, Suspense, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import PublicRoute from "./components/auth/PublicRoute";
import Navigate from "./shared/navigate/Navigate";
import { ThreeDots } from "react-loader-spinner";
import AccountRoot from "./components/accout-settings/AccountRoot";
import BookingDetails from "./components/accout-settings/account-booking/active-bookings/BookingDetails";
import AccountSettingsInfo from "./components/accout-settings/account-settings/AccountSettingsInfo";
import BookingInformation from "./components/activity/booking/BookingInformation";
import Payment from "./components/payment/Payment";
import { BookingTrackingProvider } from "./contexts/BookingTrackingContext";
import TokenVerfication from "./components/payment/TokenVerfication";
import { TotalProvider } from "./contexts/TotalContext";
import TagManager from "react-gtm-module";
import axiosInstance from "./axios/axiosInstance";
// Lazy load the components
const Root = lazy(() => import("./components/root/Root"));
const Signin = lazy(() => import("./components/auth/Signin"));
const Signup = lazy(() => import("./components/auth/Signup"));
const Error = lazy(() => import("./components/error/Error"));
const CompleteBooking = lazy(() =>
  import("./components/activity/booking/CompleteBooking")
);

const Ticket = lazy(() => import("./components/ticket/Ticket"));
const MyBookings = lazy(() =>
  import("./components/accout-settings/account-booking/MyBookings")
);

const ActivityPage = lazy(() =>
  import("./components/activity/activity-page/ActivityPage")
);
const Booking = lazy(() => import("./components/activity/booking/Booking"));

const ForgetPassword = lazy(() => import("./components/auth/ForgetPassword"));
const ResetPassword = lazy(() => import("./components/auth/ResetPassword"));
const DynamicPage = lazy(() => import("./components/dynamic-page/DynamicPage"));
const OTP = lazy(() => import("./components/auth/OTP"));
// const Payment = lazy(() => import("./components/payment/Payment"));
const UpdateBooking = lazy(() =>
  import("./components/update-booking/UpdateBooking")
);
const PaymentRedirect = lazy(() =>
  import("./components/payment/PaymentRedirect")
);
const PaymentSuccess = lazy(() =>
  import("./components/payment/PaymentSuccess")
);
const TicketBookedSuccessfully = lazy(() =>
  import("./components/ticket/TicketBookedSuccess")
);
const CompleteRegisteration = lazy(() =>
  import("./components/auth/CompleteSignup")
);
const UserSplitPayment = lazy(() =>
  import("./components/payment/UserSplitPayment")
);

const PendingBooking = lazy(() =>
  import("./components/payment/PendingBooking")
);
const Memberships = lazy(() => import("./components/memberships/Memberships"));
const TourBookedSuccess = lazy(() =>
  import("./components/book-a-tour/TourBookedSuccess")
);

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [globalData, setGlobalData] = useState(null);
  console.log("🚀 ~ App ~ globalData:", globalData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/global-data/login/data");
        if (response.status === 200) {
          setGlobalData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <BookingTrackingProvider>
          <Root globalData={globalData} />
        </BookingTrackingProvider>
      ),
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <DynamicPage />,
        },
        { path: "/:parentSlug/:slug", element: <DynamicPage /> },
        { path: "/:slug", element: <DynamicPage /> },
        {
          path: "/account",
          element: (
            <ProtectedRoute>
              <AccountRoot />
            </ProtectedRoute>
          ),
          errorElement: <Error />,
          children: [
            {
              index: true,
              element: <Navigate to="/account/account-details" />,
            },
            {
              path: "/account/my-booking",
              element: <MyBookings />,
            },
            {
              path: "/account/my-booking/booking-details",
              element: <BookingDetails />,
            },
            {
              path: "/account/account-details",
              element: <AccountSettingsInfo />,
            },
          ],
        },
        // {
        //   path: "/book-a-tour",
        //   element: <BookTour />,
        // },
        {
          path: "/memberships",
          element: <Memberships />,
        },
        {
          path: "/ticket",
          element: <Ticket />,
        },
        {
          path: "/ticket-booked-successfully",
          element: <TicketBookedSuccessfully />,
        },
        {
          path: "/tour-booked-success",
          element: <TourBookedSuccess />,
        },
        {
          path: "/user-payment",
          element: <UserSplitPayment />,
        },
        {
          path: "/activities/:slug",
          element: <ActivityPage />,
        },
        {
          path: "/booking",
          element: (
            <BookingTrackingProvider>
              <Booking />
            </BookingTrackingProvider>
          ),
        },
        {
          path: "/booking-information",
          element: (
            <BookingTrackingProvider>
              <BookingInformation />
            </BookingTrackingProvider>
          ),
        },
        {
          path: "/update-booking",
          element: <UpdateBooking />,
        },
        {
          path: "/payment",

          element: (
            <BookingTrackingProvider>
              <TotalProvider>
                <Payment />
              </TotalProvider>
            </BookingTrackingProvider>
          ),
        },
        {
          path: "bookings/complete-booking",
          element: <CompleteBooking />,
        },
        {
          path: "/callback",
          element: <PaymentRedirect />,
        },
        {
          path: "/payment-success",
          element: <PaymentSuccess />,
        },
        {
          path: "/pending-booking",
          element: <PendingBooking />,
        },
      ],
    },
    {
      path: "/signup",
      element: (
        <PublicRoute>
          <Signup signupImage={globalData?.register_page_image} />
        </PublicRoute>
      ),
    },

    // {
    //   path: "/complete-registeration",
    //   element: <CompleteRegisteration />,
    // },
    // {
    //   path: "/complete-with-token/:complete_data_token",
    //   element: <TokenVerfication />,
    // },

    // complete-with-token/oWqsACZ5PyxV8L4PqTqV?booking_id=2084

    {
      path: "/complete-with-token/:complete_data_token",
      element: <CompleteRegisteration signupImage={globalData?.register_page_image}  />,
    },

    {
      path: "/login",
      element: (
        <PublicRoute>
          <Signin loginImage={globalData?.login_page_image} />
        </PublicRoute>
      ),
    },
    {
      path: "/forget-password",
      element: (
        <PublicRoute>
          <ForgetPassword loginImage={globalData?.login_page_image} />
        </PublicRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
    },
    {
      path: "/otp",
      element: (
        <PublicRoute>
          <OTP />
        </PublicRoute>
      ),
    },
    { path: "page-not-found", element: <Error /> },
    { path: "*", element: <Error /> },
  ]);

  useEffect(() => {
    if (globalData?.gtm_id) {
      TagManager.initialize({ gtmId: globalData.gtm_id });
    }
  }, [globalData?.gtm_id]); // Run only when gtm_id is available


  if (!isOnline) {
    return (
      <div className="w-full flex flex-col  items-center justify-center my-[6rem] mx-auto offline">
        <img
          src="https://seroza-assets-bucket.s3.eu-north-1.amazonaws.com/assets/EmptyState.svg"
          className="w-[25%]"
          alt="no internet icon"
          srcSet="no internet icon"
        />
        <h1 className="text-[#2A2A2A] text-[24px] md:text-[32px] lg:text-[36px] font-bold  leading-6 mb-[2rem]">
          No internet Connection
        </h1>
        <p>
          You are offline. Please check your network cables, modem, and router
          then try to reconnect to Wi-Fi.
        </p>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;


