import React, { useEffect } from "react";
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const TokenVerfication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("🚀 ~ TokenVerfication ~ location:", location);
  const { complete_data_token } = useParams(); // This matches the parameter name in the route

  console.log(
    "complete_data_token from token verfication page ",
    complete_data_token
  );
  useEffect(() => {
    const verfiyToken = async () => {
      try {
        const response = await axiosInstance.post(
          "/sports/check-complete-data-token",
          {
            token: complete_data_token,
          }
        );
        if (response.data.status === "success") {
          // redirect to the "/complete-registeration" page with the token as state
          navigate("/complete-registeration", {
            state: {
              complete_data_token,
              bookingId: location?.state?.bookingId,
            },
          });
        } else {
          navigate("/page-not-found");
        }
      } catch (error) {
        navigate("/page-not-found");
      }
    };
    if (complete_data_token) {
      verfiyToken();
    }
  }, []);

  return <></>;
};

export default TokenVerfication;
