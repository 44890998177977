import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useBooking } from "../../contexts/BookingContext";
import { useBookingTracking } from "../../contexts/BookingTrackingContext";
import { useNavigate } from "react-router-dom";
import ButtonGrey from "../../shared/buttons/ButtonGrey";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import ButtonWhite from "../../shared/buttons/ButtonWhite";
import ButtonPrimary from "../../shared/buttons/ButtonPrimary";
import bookingIcon from "../../assets/bookingIcon.svg";
import { useUser } from "../../contexts/UserContext";
import { useLanguage } from "../../contexts/LanguageContext";

const CheckingExistingBookingModal = ({
  button_type = null,
  page_slug = null,
  button_primary = "true",
  button_secondary = "false",
  button_white = "false",
  hasMarginTop = "no",
  has_booking_icon = "false",
  newText = null,
  shouldOpenModal = true, // default to true if not provided
}) => {

  const { language } = useLanguage();
  const { user } = useUser();
  const { clearBookingState,resetWomanDay,clearFreeActivities,clearTicketDetails,clearTicketIncludes } = useBooking();
  const { currentPage, clearTrackingState } = useBookingTracking() || {};
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasExistingBooking, setHasExistingBooking] = useState(false); // Track if there is an existing booking

  // Use 'kona_currentBooking' as the key if no user is logged in
  const bookingKey = user?.id || "kona_currentBooking";

  const savedBookings = JSON.parse(localStorage.getItem("bookings") || "{}");
  const userBooking = savedBookings[bookingKey];

  // Check if there's an existing booking (this can be dynamic based on your actual app logic)
  useEffect(() => {
    setHasExistingBooking(!!userBooking && !!userBooking.booking_date);
  }, [userBooking]);

  // Open the modal when there's an existing booking
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Proceed with the existing booking
  const handleOk = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      navigate(currentPage ? currentPage : "booking-information", {});
    }, 500);
  };

  // Start a new booking
  const handleNewBooking = () => {
    console.log("Starting a new booking");
    clearBookingState();
    clearTicketDetails();
    clearTrackingState();
    resetWomanDay();
    clearFreeActivities();
    clearTicketIncludes();
    setIsModalOpen(false);
    localStorage.removeItem("selectedType");
    localStorage.removeItem("kona_freeActivities");
    localStorage.removeItem("kona_ticketIncludes");
    
    setTimeout(() => {
      navigate("/booking", {});
    }, 500);
    localStorage.removeItem("selectedGender");
  };

  // Close modal handler
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Button click logic
  // const handleBookingClick = () => {
  //   if (button_type === "bookings") {
  //     if (userBooking && userBooking.booking_date) {
  //       // If user and existing booking, show modal
  //       showModal();
  //     } else {
  //       // If user and no existing booking, go to booking
  //       navigate("/booking");
  //     }
  //   } else if (button_type === "page") {
  //     // If button_type is page, navigate to the page_slug
  //     navigate(`/${page_slug}`);
  //   }
  // };

  // Show the modal if there’s an existing booking and shouldOpenModal is true
  const handleBookingClick = () => {
    if (button_type === "bookings") {
      if (userBooking && userBooking.booking_date) {
        if (shouldOpenModal) {
          setIsModalOpen(true); // Open the modal if the flag is true
        } else {
          navigate(currentPage ? currentPage : "booking-information", {});
        }
      } else {
        // No existing booking, navigate to booking page
        navigate("/booking");
      }
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };
  const getButtonTitle = () => {
    // If newText is provided, prioritize it
    if (newText) return newText;

    // Default text based on button type and language
    if (button_type === "bookings") {
      return language === "en" ? "Book Ticket" : "احجز تذكره";
    }
    if (button_type === "page") {
      return language === "en" ? "Learn More" : "تعرف على المزيد";
    }
  };

  return (
    <>
      {/* Modal Logic */}
      <Modal
        title={null}
        open={isModalOpen}
        className="py-6 px-4"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="w-full flex flex-col items-center justify-center gap-2">
          <img src={bookingIcon} alt="booking icon" />
          <h3 className="text-[#2A2A2A] text-[18px] font-semibold md:text-[22px] lg:text-[26px] leading-none text-center mt-2">
            {language === "en"
              ? "You Have a Pending Booking!"
              : "لديك حجز معلق!"}
          </h3>
          <p className="text-[#5E5E5E] font-normal text-[14px] md:text-[16px] leading-6 text-center mt-2">
            {language === "en"
              ? "You already have a not completed booking, you can proceed from where you left or start a new booking."
              : "لديك حجز غير مكتمل بالفعل، يمكنك المتابعة من حيث توقفت أو بدء حجز جديد."}
          </p>
          <div className="w-full flex items-center justify-center gap-2 mt-[1rem]">
            <ButtonGrey onClick={handleNewBooking} className={`py-4`}>
              {language === "en" ? "Start New Booking" : "ابدأ حجز جديد"}
            </ButtonGrey>
            <ButtonSecondary onClick={handleOk} className={`py-4`}>
              {language === "en" ? "Complete Booking" : "اكمل الحجز"}
            </ButtonSecondary>
          </div>
        </div>
      </Modal>

      {/* Booking or Tour Button */}
      <div className={`${hasMarginTop === "yes" ? "mt-4" : null}`}>
        {button_primary === "true" && (
          <ButtonPrimary
            className={`cursor-pointer`}
            onClick={handleBookingClick}
          >
            {has_booking_icon === "true" && (
              <span className="self-center">
                <svg
                  className="self-center"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49412 9.0601V8.02628M8.49412 13.7123V12.6785M8.49412 18.3645V17.3307M7.22001 21.466H18.2624C20.0463 21.466 20.9383 21.466 21.6196 21.1279C22.219 20.8306 22.7063 20.3561 23.0117 19.7726C23.3588 19.1091 23.3588 18.2406 23.3588 16.5036V9.88716C23.3588 8.15017 23.3588 7.28168 23.0117 6.61824C22.7063 6.03466 22.219 5.56019 21.6196 5.26284C20.9383 4.9248 20.0463 4.9248 18.2624 4.9248H7.22001C5.43607 4.9248 4.54411 4.9248 3.86274 5.26284C3.26338 5.56019 2.7761 6.03466 2.47071 6.61824C2.12354 7.28168 2.12354 8.15017 2.12354 9.88716V16.5036C2.12354 18.2406 2.12354 19.1091 2.47071 19.7726C2.7761 20.3561 3.26338 20.8306 3.86274 21.1279C4.54411 21.466 5.43607 21.466 7.22001 21.466Z"
                    stroke="white"
                    strokeWidth="2.68235"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            {getButtonTitle()}
          </ButtonPrimary>
        )}
        {button_secondary === "true" && (
          <ButtonSecondary
            className={`cursor-pointer`}
            onClick={handleBookingClick}
          >
            {has_booking_icon === "true" && (
              <span className="self-center">
                <svg
                  className="self-center"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49412 9.0601V8.02628M8.49412 13.7123V12.6785M8.49412 18.3645V17.3307M7.22001 21.466H18.2624C20.0463 21.466 20.9383 21.466 21.6196 21.1279C22.219 20.8306 22.7063 20.3561 23.0117 19.7726C23.3588 19.1091 23.3588 18.2406 23.3588 16.5036V9.88716C23.3588 8.15017 23.3588 7.28168 23.0117 6.61824C22.7063 6.03466 22.219 5.56019 21.6196 5.26284C20.9383 4.9248 20.0463 4.9248 18.2624 4.9248H7.22001C5.43607 4.9248 4.54411 4.9248 3.86274 5.26284C3.26338 5.56019 2.7761 6.03466 2.47071 6.61824C2.12354 7.28168 2.12354 8.15017 2.12354 9.88716V16.5036C2.12354 18.2406 2.12354 19.1091 2.47071 19.7726C2.7761 20.3561 3.26338 20.8306 3.86274 21.1279C4.54411 21.466 5.43607 21.466 7.22001 21.466Z"
                    stroke="white"
                    strokeWidth="2.68235"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            {getButtonTitle()}
          </ButtonSecondary>
        )}

        {button_white === "true" && (
          <ButtonWhite
            className={`cursor-pointer`}
            onClick={handleBookingClick}
          >
            {has_booking_icon === "true" && (
              <span className="self-center">
                <svg
                  className="self-center"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49412 9.0601V8.02628M8.49412 13.7123V12.6785M8.49412 18.3645V17.3307M7.22001 21.466H18.2624C20.0463 21.466 20.9383 21.466 21.6196 21.1279C22.219 20.8306 22.7063 20.3561 23.0117 19.7726C23.3588 19.1091 23.3588 18.2406 23.3588 16.5036V9.88716C23.3588 8.15017 23.3588 7.28168 23.0117 6.61824C22.7063 6.03466 22.219 5.56019 21.6196 5.26284C20.9383 4.9248 20.0463 4.9248 18.2624 4.9248H7.22001C5.43607 4.9248 4.54411 4.9248 3.86274 5.26284C3.26338 5.56019 2.7761 6.03466 2.47071 6.61824C2.12354 7.28168 2.12354 8.15017 2.12354 9.88716V16.5036C2.12354 18.2406 2.12354 19.1091 2.47071 19.7726C2.7761 20.3561 3.26338 20.8306 3.86274 21.1279C4.54411 21.466 5.43607 21.466 7.22001 21.466Z"
                    stroke="white"
                    strokeWidth="2.68235"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            {getButtonTitle()}
          </ButtonWhite>
        )}
      </div>
    </>
  );
};

export default CheckingExistingBookingModal;
