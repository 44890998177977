import React from "react";

const ButtonCancel = ({
  type = "button",
  style,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <>
      <button
        type={type}
        style={style}
        disabled={disabled}
        className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-transparent border border-[#DA4040]
        py-[6px] px-[14px]  md:py-[8px] md:px-[20px] rounded-[100px] text-[#DA4040]
        disabled:opacity-50  disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-[#DA4040]
        
                 font-normal leading-6 text-[12px] md:text-[16px] hover:bg-[#DA4040] hover:text-white transition-all duration-200 cursor-pointer
                 xs:min-w-[80px] xs:py-[7px] xs:px-[14px]  ${className}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default ButtonCancel;
