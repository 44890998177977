import React from "react";
import GuestForm from "./GuestForm";
import ActivitiesDrawer from "./ActivitiesDrawer";
import FoodDrawer from "./FoodDrawer";
import BlurFade from "../../../components/ui/blur-fade";

import { useBooking } from "../../../contexts/BookingContext";

const GuestBookingInfo = React.memo(
  ({ user, activities, food, index, type, name, errors }) => {
    const { booking } = useBooking();

    const currentUser = booking?.booking_users?.[index]; // Assuming you're iterating over `booking_users`
    const memberType = currentUser?.member_type; // Default to 'guest' if member_type is not available

    return (
      <>
        <BlurFade className={`w-full`} delay={0.13}>
          <div className="kn-guest-booking-info">
            <GuestForm user={user} index={index} memberType={memberType} errors={errors} />
            {/* Activity Add Cta */}
            <ActivitiesDrawer
              activities={activities}
              currentIndex={index}
              currentUserType={type}
              user={user}
            />
            {/* Meals Add Cta */}
            <FoodDrawer
              currentIndex={index}
              currentUserType={type}
              food={food}
            />
          </div>
        </BlurFade>
      </>
    );
  }
);

export default GuestBookingInfo;
