import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "./UserContext";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../axios/axiosInstance";

const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const { user } = useUser();

  const loadInitialState = () => {
    const savedBookings = JSON.parse(localStorage.getItem("bookings") || "{}");

    // Use 'kona_currentBooking' if there's no user logged in
    let bookingKey = user?.id ? user.id : "kona_currentBooking";

    // Add a guard to ensure bookingKey is never undefined
    if (!bookingKey || bookingKey === "undefined") {
      bookingKey = "kona_currentBooking";
    }

    const userBooking = savedBookings[bookingKey] || null;

    return userBooking
      ? userBooking
      : {
          booking_platform: "Web",
          booking_date: null,
          coupon_code: null,
          payment_type: "pay in full",
          payment_method: null,
          booking_users: [
            {
              name: user?.name || "",
              email: user?.email || "",
              phone: user?.phone || "",
              member_type: "member",
              payment_status: "paid",
              booking_activities: [],
              booking_food: [],
              type: "adult",
              user_id: user?.id || uuidv4(),
            },
          ],
          booking_dependents: [],
        };
  };

  // UseEffect to save the booking information

  // function to initialize default booking state
  const clearBookingStateDefault = () => {
    // Use 'kona_currentBooking' if there's no user logged in
    let bookingKey = user?.id || "kona_currentBooking";

    // Add a guard to ensure bookingKey is never undefined
    if (!bookingKey || bookingKey === "undefined") {
      bookingKey = "kona_currentBooking";
    }

    // Clear only the relevant parts of the booking object
    const savedBookings = JSON.parse(localStorage.getItem("bookings") || "{}");

    savedBookings[bookingKey] = {
      booking_platform: "Web",
      booking_date: null,
      coupon_code: null,
      payment_type: "pay in full",
      payment_method: null,
      booking_users: [], // Clear booking users
      booking_dependents: [], // Clear booking dependents
    };

    // Update localStorage without removing the entire key
    localStorage.setItem("bookings", JSON.stringify(savedBookings));

    // Clear the state within the app
    setBooking({
      booking_platform: "Web",
      booking_date: null,
      coupon_code: null,
      payment_type: "pay in full",
      payment_method: null,
      booking_users: [],
      booking_dependents: [],
    });

    // Clear the local arrays that might be storing booking data
    setBookingUsers([]);
    setBookingDependents([]);
    setBookingSummary(null);
    set_coupon_code(null);
  };

  const [booking, setBooking] = useState(loadInitialState());
  const [bookingUsers, setBookingUsers] = useState([]);
  const [bookingDependents, setBookingDependents] = useState([]);
  const [bookingSummary, setBookingSummary] = useState(null);
  const [coupon_code, set_coupon_code] = useState(null);

  const [guestPrice, setGuestPrice] = useState(null);
  const [dependentPrice, setDependentPrice] = useState(null);
  const [selectedUsersForPayment, setSelectedUsersForPayment] = useState([0]);

  useEffect(() => {
    let bookingKey = user?.id || "kona_currentBooking";

    if (bookingKey) {
      // Load the user's booking from localStorage
      const savedBookings = JSON.parse(
        localStorage.getItem("bookings") || "{}"
      );
      const userBooking = savedBookings[bookingKey] || {
        booking_platform: "Web",
        booking_date: null,
        coupon_code: null,
        payment_type: "pay in full",
        payment_method: null,
        booking_users: [],
        booking_dependents: [],
      };
      setBooking(userBooking); // Set booking state for the user
    }
  }, [user]);

  // UseEffect to save the booking information
  useEffect(() => {
    // Use 'kona_currentBooking' if there's no user logged in
    let bookingKey = user?.id || "kona_currentBooking";

    // Add a guard to ensure bookingKey is never undefined
    if (!bookingKey || bookingKey === "undefined") {
      bookingKey = "kona_currentBooking";
    }

    const savedBookings = JSON.parse(localStorage.getItem("bookings") || "{}");

    // Save the booking under the correct key
    savedBookings[bookingKey] = booking;

    // Update localStorage
    localStorage.setItem("bookings", JSON.stringify(savedBookings));
  }, [booking, user]);

  // wanna keep track of the primary user if it changes then update the booking summary
  const primaryUser = booking.booking_users.find((user) => user.primary);
  useEffect(() => {
    updateBookingSummary(); // Trigger summary update when changes detected
  }, [
    booking.booking_users.length,
    booking.booking_dependents.length,
    booking.payment_type,
    selectedUsersForPayment,
    primaryUser,
  ]);

  useEffect(() => {
    // Clean up any 'undefined' keys left in localStorage
    const savedBookings = JSON.parse(localStorage.getItem("bookings") || "{}");
    if (savedBookings["undefined"]) {
      delete savedBookings["undefined"];
      localStorage.setItem("bookings", JSON.stringify(savedBookings));
    }
  }, []);

  const setPaymentMethod = (method) => {
    setBooking((prevState) => {
      return {
        ...prevState,
        payment_method: method,
      };
    });
  };

  const handleUserCheckboxToggle = (idOrIndex, isChecked) => {
    setBooking((prevState) => {
      const updatedUsers = prevState.booking_users.map((user, i) => {
        if (i === idOrIndex) {
          return { ...user, payment_status: isChecked ? "paid" : "not paid" };
        }
        return user;
      });

      return { ...prevState, booking_users: updatedUsers };
    });

    if (isChecked) {
      setSelectedUsersForPayment((prevSelected) => [
        ...prevSelected,
        idOrIndex,
      ]);
    } else {
      setSelectedUsersForPayment((prevSelected) =>
        prevSelected.filter((i) => i !== idOrIndex)
      );
    }
  };

  const clearBookingState = () => {
    setBooking({
      booking_platform: "Web",
      booking_date: null,
      coupon_code: null,
      payment_type: "pay in full",
      payment_method: null,
      booking_users: [], // Clear out all booking users
      booking_dependents: [], // Clear dependents
    });

    if (user?.id) {
      const savedBookings = JSON.parse(
        localStorage.getItem("bookings") || "{}"
      );
      delete savedBookings[user.id || "kona_currentBooking"]; // Remove the current user's booking
      localStorage.setItem("bookings", JSON.stringify(savedBookings));
    }
  };

  const updateBookingSummary = async () => {
    if (!booking || !booking.booking_date) {
      console.log(
        "Booking date is not set or booking is null/undefined, skipping summary update."
      );

      return;
    }

    const summaryObject = transformBookingForSummary();

    if (booking.payment_type === "split") {
      try {
        const response = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: booking?.booking_date,
          payment_type: booking?.payment_type || "pay in full",
          coupon_code: booking?.coupon_code || null,
          booking_users: summaryObject?.booking_users, // Use filtered users
          booking_dependents: summaryObject?.booking_dependents, // Use filtered dependents
        });

        if (response.status === 200) {
          setBookingSummary(response.data.data);
        }
      } catch (error) {
        console.error("Error updating booking summary:", error);
        setBookingSummary(null);
      }
    } else {
      // Filter booking_users with non-empty name, email, and phone
      // const filteredUsers = booking?.booking_users?.filter(
      //   (user) => user?.name?.trim() !== ""
      // );

      // // Filter booking_dependents with non-empty name
      // const filteredDependents = booking?.booking_dependents?.filter(
      //   (dependent) => dependent?.name?.trim() !== ""
      // );

      try {
        const response = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: booking?.booking_date,
          payment_type: booking?.payment_type || "pay in full",
          coupon_code: booking?.coupon_code || null,
          booking_users: booking?.booking_users, // Use filtered users
          booking_dependents: booking?.booking_dependents, // Use filtered dependents
        });

        if (response.status === 200) {
          setBookingSummary(response.data.data);
        }
      } catch (error) {
        console.error("Error updating booking summary:", error);
        setBookingSummary(null);
      }
    }
  };

  const getPersonByIndexAndType = (index, type) => {
    if (type !== "adult" && type !== "dependent") {
      console.error("Invalid type specified. Must be 'adult' or 'dependent'.");
      return null;
    }

    const arrayToSearch =
      type === "adult" ? booking.booking_users : booking.booking_dependents;

    if (index < 0 || index >= arrayToSearch.length) {
      console.error("Index out of bounds");
      return null;
    }

    return arrayToSearch[index];
  };

  const updateBookingDate = (date) => {
    setBooking({
      ...booking,
      booking_date: date,
    });
  };

  const updateCouponCode = (code) => {
    set_coupon_code(code);
    setBooking((prevState) => ({
      ...prevState,
      coupon_code: code,
    }));
  };

  const getCouponCode = () => {
    return coupon_code;
  };

  const updatePaymentType = (type) => {
    setBooking((prevState) => {
      const updatedState = { ...prevState, payment_type: type };

      if (type === "pay in full") {
        // Set all users and dependents to "paid" status
        updatedState.booking_users = prevState.booking_users.map((user) => ({
          ...user,
          payment_status: "paid",
        }));

        updatedState.booking_dependents = prevState.booking_dependents.map(
          (dependent) => ({
            ...dependent,
            payment_status: "paid",
          })
        );
      } else if (type === "split") {
        // For split payment, mark only the first user as "paid"
        setSelectedUsersForPayment([0]);
        updatedState.booking_users = prevState.booking_users.map(
          (user, index) => ({
            ...user,
            payment_status: index === 0 ? "paid" : "not paid",
          })
        );
        updatedState.booking_dependents = prevState.booking_dependents.map(
          (dependent) => ({
            ...dependent,
            payment_status: "paid",
          })
        );
      }

      // Update local storage with the new payment type and statuses
      const savedBookings = JSON.parse(
        localStorage.getItem("bookings") || "{}"
      );
      const bookingKey = user?.id || "kona_currentBooking";
      savedBookings[bookingKey] = updatedState;
      localStorage.setItem("bookings", JSON.stringify(savedBookings));

      return updatedState;
    });
  };

  const getNextIndexForGender = (users, gender) => {
    // Find the highest current index for the specified gender
    const regex = new RegExp(`^${gender} (\\d+)$`, "i"); // Case-insensitive match
    const indices = users
      .filter((user) => regex.test(user.name))
      .map((user) => parseInt(user.name.match(regex)[1], 10));

    return indices.length ? Math.max(...indices) + 1 : 1;
  };

  // const setPrimaryUser = (index) => {
  //   setBooking((prevBooking) => {
  //     const updatedUsers = prevBooking.booking_users.map((user, i) => ({
  //       ...user,
  //       primary: i === index, // Set primary to true for the selected index, false for others
  //     }));

  //     // Move the primary user to the beginning of the array
  //     const reorderedUsers = [
  //       ...updatedUsers.filter((user) => user.primary),
  //       ...updatedUsers.filter((user) => !user.primary),
  //     ];

  //     return {
  //       ...prevBooking,
  //       booking_users: reorderedUsers,
  //     };
  //   });
  // };

  const initializeBookingMen = (quantity, selectedGender) => {
    const users = [];
    const existingUsers = booking.booking_users.filter(
      (user) => user.gender === "male"
    );

    for (let i = 0; i < quantity; i++) {
      const paymentStatus = i === 0 ? "paid" : "not paid";

      // If logged-in user is male and initializing the first male user
      if (i === 0 && user && user.gender === "male") {
        users.push({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          gender: user.gender,
          member_type: "daypass",
          user_id: null,
          primary: true,
          type: "adult",
        });
      } else {
        users.push({
          first_name:
            i < existingUsers.length ? existingUsers[i].first_name : ``,
          last_name: i < existingUsers.length ? existingUsers[i].last_name : ``,
          email: i < existingUsers.length ? existingUsers[i].email : "",
          phone: i < existingUsers.length ? existingUsers[i].phone : "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          gender: "male",
          member_type: "daypass",
          type: "adult",
          user_id: i < existingUsers.length ? existingUsers[i].user_id : null,
          primary: i === 0 && selectedGender === "male",
        });
      }
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users.filter((user) => user.gender !== "male"),
        ...users,
      ],
    }));
  };

  const initializeBookingWomen = (quantity, selectedGender) => {
    const users = [];
    const existingUsers = booking.booking_users.filter(
      (user) => user.gender === "female"
    );

    for (let i = 0; i < quantity; i++) {
      const paymentStatus = i === 0 ? "paid" : "not paid";

      // If logged-in user is female and initializing the first female user
      if (i === 0 && user && user.gender === "female") {
        users.push({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          gender: user.gender,
          member_type: "daypass",
          user_id: null,
          primary: true,
          type: "adult",
        });
      } else {
        users.push({
          first_name:
            i < existingUsers.length ? existingUsers[i].first_name : ``,
          last_name: i < existingUsers.length ? existingUsers[i].last_name : ``,
          name: i < existingUsers.length ? existingUsers[i].name : ``,
          email: i < existingUsers.length ? existingUsers[i].email : "",
          phone: i < existingUsers.length ? existingUsers[i].phone : "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          gender: "female",
          member_type: "daypass",
          type: "adult",
          user_id: i < existingUsers.length ? existingUsers[i].user_id : null,
          primary: i === 0 && selectedGender === "female",
        });
      }
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users.filter((user) => user.gender !== "female"),
        ...users,
      ],
    }));
  };

  // Helper function to get the next index based on member type
  const getNextIndexForMemberType = (users, memberType) => {
    const regex = new RegExp(
      `^${memberType.charAt(0).toUpperCase() + memberType.slice(1)} (\\d+)$`,
      "i"
    );
    const indices = users
      .filter((user) => regex.test(user.name))
      .map((user) => parseInt(user.name.match(regex)[1], 10));

    return indices.length ? Math.max(...indices) + 1 : 1;
  };

  const initializeBookingJuniors = (quantity) => {
    const users = [];
    const existingUsers = booking.booking_dependents.filter(
      (user) => user.member_type === "junior"
    );
    let juniorIndex = getNextIndexForMemberType(existingUsers, "junior");

    for (let i = 0; i < quantity; i++) {
      const paymentStatus = i === 0 ? "paid" : "not paid";

      if (i < existingUsers.length) {
        // Reuse existing junior user if already present
        users.push(existingUsers[i]);
      } else {
        // Add new junior user with the incremented index
        users.push({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          user_id: null,
          gender: null,
          member_type: "junior",
          type: "adult",
          birthdate: "",
        });
        juniorIndex++;
      }
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents.filter(
          (user) => user.member_type !== "junior"
        ),
        ...users,
      ],
    }));
  };

  const initializeBookingKids = (quantity) => {
    const users = [];
    const existingUsers = booking.booking_dependents.filter(
      (user) => user.member_type === "kid"
    );
    let kidIndex = getNextIndexForMemberType(existingUsers, "kid");

    for (let i = 0; i < quantity; i++) {
      const paymentStatus = i === 0 ? "paid" : "not paid";

      if (i < existingUsers.length) {
        // Reuse existing kid user if already present
        users.push(existingUsers[i]);
      } else {
        // Add new kid user with the incremented index
        users.push({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          user_id: null,
          gender: null,
          member_type: "kid",
          type: "adult",
          birthdate: "",
        });
        kidIndex++;
      }
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents.filter(
          (user) => user.member_type !== "kid"
        ),
        ...users,
      ],
    }));
  };

  // Function to add a man to booking
  const addManToBooking = () => {
    const existingUsers = booking.booking_users.filter(
      (user) => user.gender === "male"
    );
    let maleIndex = getNextIndexForGender(existingUsers, "Man");

    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users,
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          gender: "male",
          member_type: "daypass",
          type: "adult",
          user_id: null,
          primary: false,
        },
      ],
    }));
  };

  // Function to add a woman to booking
  const addWomanToBooking = () => {
    const existingUsers = booking.booking_users.filter(
      (user) => user.gender === "female"
    );
    let femaleIndex = getNextIndexForGender(existingUsers, "Woman");

    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users,
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          gender: "female",
          member_type: "daypass",
          type: "adult",
          user_id: null,
          primary: false,
        },
      ],
    }));
  };

  // Function to add a junior to booking
  const addJuniorToBooking = () => {
    const existingUsers = booking.booking_dependents.filter(
      (user) => user.member_type === "junior"
    );
    let juniorIndex = getNextIndexForGender(existingUsers, "Junior");

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents,
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          member_type: "junior",
          type: "dependent",
          birthdate: "",
          user_id: null,
        },
      ],
    }));
  };

  // function to switch primary user
  const setPrimaryUser = (index) => {
    setBooking((prevBooking) => {
      const updatedUsers = prevBooking.booking_users.map((user, i) => ({
        ...user,
        primary: i === index, // Set primary to true for the selected index, false for others
      }));

      // Move the primary user to the beginning of the array
      const reorderedUsers = [
        ...updatedUsers.filter((user) => user.primary),
        ...updatedUsers.filter((user) => !user.primary),
      ];

      return {
        ...prevBooking,
        booking_users: reorderedUsers,
      };
    });
    // wanna
  };

  // Function to add a kid to booking
  const addKidToBooking = () => {
    const existingUsers = booking.booking_dependents.filter(
      (user) => user.member_type === "kid"
    );
    let kidIndex = getNextIndexForGender(existingUsers, "Kid");

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents,
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          member_type: "kid",
          type: "dependent",
          birthdate: "",
          user_id: null,
        },
      ],
    }));
  };

  const addBookingUser = () => {
    setBookingUsers([
      ...bookingUsers,
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        payment_status: "not paid",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        member_type: "guest",
        type: "adult",
      },
    ]);
    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users,
        {
          name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          id: uuidv4(),
          member_type: "guest",
          type: "adult",
        },
      ],
    }));
  };

  const removeBookingUser = (index) => {
    setBooking((prevBooking) => {
      const updatedUsers = prevBooking.booking_users.filter(
        (_, i) => i !== index
      );

      // Optionally, reassign names based on the updated array to avoid gaps in names

      return {
        ...prevBooking,
        booking_users: updatedUsers,
      };
    });
  };

  const updateCurrentUser = (index, updatedUser) => {
    setBookingUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, ...updatedUser } : user
      )
    );
    setBooking((prevState) => ({
      ...prevState,
      booking_users: prevState.booking_users.map((user, i) =>
        i === index ? { ...user, ...updatedUser } : user
      ),
    }));

    // updateBookingSummary();
  };

  const initializeBookingDependents = (dependentQuantity) => {
    const dependents = [];
    for (let i = 0; i < dependentQuantity; i++) {
      dependents.push({
        first_name: "",
        last_name: "",
        birthdate: "",
        gender: "",
        relationship: "",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        member_type: "guest",
        type: "dependent",
      });
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: dependents,
    }));
    setBookingDependents(dependents);
  };

  const addBookingDependent = () => {
    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents,
        {
          first_name: "",
          last_name: "",
          birthdate: "",
          gender: "",
          relationship: "",
          booking_activities: [],
          booking_food: [],
          id: uuidv4(),
          member_type: "guest",
          type: "dependent",
        },
      ],
    }));
    setBookingDependents([
      ...bookingDependents,
      {
        first_name: "",
        last_name: "",
        birthdate: "",
        gender: "",
        relationship: "",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        member_type: "guest",
        type: "dependent",
      },
    ]);
  };

  const removeBookingDependent = (index) => {
    setBooking((prevState) => {
      const updatedDependents = prevState.booking_dependents.filter(
        (_, i) => i !== index
      );

      // Do not reassign names, simply remove the selected dependent
      return {
        ...prevState,
        booking_dependents: updatedDependents,
      };
    });

    // Update the state without reassigning or renaming dependents
    setBookingDependents((prevDependents) =>
      prevDependents.filter((_, i) => i !== index)
    );
  };

  const updateBookingDependent = (index, updatedDependent) => {
    setBookingDependents((prevDependents) =>
      prevDependents.map((dependent, i) =>
        i === index ? { ...dependent, ...updatedDependent } : dependent
      )
    );
    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: prevState.booking_dependents.map((dependent, i) =>
        i === index ? { ...dependent, ...updatedDependent } : dependent
      ),
    }));
  };

  const onRemoveCouponFromBooking = () => {
    set_coupon_code(null);
    setBooking((prevState) => ({
      ...prevState,
      coupon_code: null,
    }));
  };

  const transformBookingForSummary = () => {
    const transformedBooking = { ...booking };

    // In split payment, include all family members and selected guests for payment
    if (booking.payment_type === "split") {
      transformedBooking.booking_users = transformedBooking.booking_users
        .filter((user, index) => selectedUsersForPayment.includes(index)) // Always include family members, and optionally include selected guests
        .map((user) => ({
          first_name: user.first_name,
          last_name: user.last_name,
          member_type: user.member_type,
          gender: user.gender,
          primary: user.primary,
          payment_status: user.payment_status,
          booking_activities: user.booking_activities, // Ensure activities are included
          booking_food: user.booking_food, // Ensure food items are included
        }));
    } else {
      transformedBooking.booking_users = transformedBooking.booking_users.map(
        (user) => ({
          first_name: user.first_name,
          last_name: user.last_name,
          member_type: user.member_type,
          gender: user.gender,
          primary: user.primary,
          payment_status: user.payment_status,
          booking_activities: user.booking_activities, // Ensure activities are included
          booking_food: user.booking_food, // Ensure food items are included
        })
      );
    }

    transformedBooking.booking_dependents =
      transformedBooking.booking_dependents.map((dependent) => ({
        first_name: dependent.first_name,
        last_name: dependent.last_name,
        birthdate: dependent.birthdate,
        payment_status: dependent.payment_status,
        member_type: dependent.member_type,
        booking_activities: dependent.booking_activities, // Ensure activities are included
        booking_food: dependent.booking_food, // Ensure food items are included
      }));

    return transformedBooking;
  };

  const removeActivityFromUser = (index, activityId, type) => {
    const targetArray =
      type === "adult" ? booking?.booking_users : booking?.booking_dependents;

    if (index < 0 || index >= targetArray?.length) {
      console.error("Invalid index");
      return;
    }

    const user = targetArray[index];

    const activityIndex = user.booking_activities.findIndex(
      (activity) => activity?.item_id === activityId
    );

    if (activityIndex === -1) {
      console.error("Activity not found");
      return;
    }

    const updatedActivities = user.booking_activities.filter(
      (_, i) => i !== activityIndex
    );

    const updatedUser = { ...user, booking_activities: updatedActivities };

    if (type === "adult") {
      setBooking((prevState) => ({
        ...prevState,
        booking_users: prevState.booking_users.map((user, i) => {
          if (i === index) {
            return {
              ...user,
              booking_activities: updatedActivities,
              selectedItems: [],
            };
          }
          return user;
        }),
      }));
    } else {
      setBooking((prevState) => ({
        ...prevState,
        booking_dependents: prevState.booking_dependents.map((dependent, i) => {
          if (i === index) {
            return {
              ...dependent,
              booking_activities: updatedActivities,
              selectedItems: [],
            };
          }
          return dependent;
        }),
      }));
    }
    updateBookingSummary(); // Update summary only when activity is removed
  };

  const addOrUpdateFoodItem = (userIndex, foodItem, type) => {
    setBooking((prevState) => {
      const newState = { ...prevState };

      const user =
        type === "adult"
          ? newState?.booking_users[userIndex]
          : newState?.booking_dependents[userIndex];

      const existingIndex = user?.booking_food?.findIndex(
        (item) => item.item_id === foodItem.item_id
      );

      if (existingIndex > -1) {
        user.booking_food[existingIndex] = foodItem;
      } else {
        user.booking_food.push(foodItem);
      }

      return newState;
    });
    updateBookingSummary(); // Update summary only when food item is added or updated
  };

  const addOrUpdateActivityItem = (userIndex, activityItem, type) => {
    setBooking((prevState) => {
      const newState = { ...prevState };

      const user =
        type === "adult"
          ? newState?.booking_users[userIndex]
          : newState?.booking_dependents[userIndex];

      const existingIndex = user?.booking_activities?.findIndex(
        (item) => item.item_id === activityItem.item_id
      );

      if (existingIndex > -1) {
        user.booking_activities[existingIndex] = activityItem;
      } else {
        user.booking_activities.push(activityItem);
      }

      return newState;
    });
    updateBookingSummary(); // Update summary only when food item is added or updated
  };

  const removeFoodItem = (userIndex, foodItemId, type) => {
    setBooking((prevState) => {
      const newState = { ...prevState };

      const user =
        type === "adult"
          ? newState?.booking_users[userIndex]
          : newState?.booking_dependents[userIndex];

      user.booking_food = user.booking_food.filter(
        (item) => item.item_id !== foodItemId
      );

      return newState;
    });
    updateBookingSummary(); // Update summary only when food item is removed
  };

  const clearActivitiesForUserByIndex = (userIndex, userType) => {
    setBooking((prevState) => {
      const arrayName =
        userType === "adult" ? "booking_users" : "booking_dependents";
      const updatedUsersArray = [...prevState[arrayName]];
      if (userIndex >= 0 && userIndex < updatedUsersArray.length) {
        updatedUsersArray[userIndex].booking_activities = [];
        return { ...prevState, [arrayName]: updatedUsersArray };
      } else {
        console.error("Index out of bounds");
        return prevState;
      }
    });
    updateBookingSummary(); // Update summary when activities are cleared
  };

  const clearFoodForUserByIndex = (userIndex, userType) => {
    setBooking((prevState) => {
      const arrayName =
        userType === "adult" ? "booking_users" : "booking_dependents";
      const updatedUsersArray = [...prevState[arrayName]];
      if (userIndex >= 0 && userIndex < updatedUsersArray.length) {
        updatedUsersArray[userIndex].booking_food = [];
        return { ...prevState, [arrayName]: updatedUsersArray };
      } else {
        console.error("Index out of bounds");
        return prevState;
      }
    });
    updateBookingSummary(); // Update summary when food items are cleared
  };

  return (
    <BookingContext.Provider
      value={{
        booking,
        updateBookingSummary,
        updateBookingDate,
        updatePaymentType,
        bookingUsers,
        initializeBookingMen,
        initializeBookingWomen,
        initializeBookingJuniors,
        initializeBookingKids,
        addBookingUser,
        updateCurrentUser,
        removeBookingUser,
        bookingDependents,
        initializeBookingDependents,
        addBookingDependent,
        updateBookingDependent,
        removeBookingDependent,
        getPersonByIndexAndType,
        transformBookingForSummary,
        removeActivityFromUser,
        addOrUpdateFoodItem,
        removeFoodItem,
        bookingSummary,
        setBookingSummary,
        updateCouponCode,
        getCouponCode,
        onRemoveCouponFromBooking,
        setBooking,
        guestPrice,
        setGuestPrice,
        dependentPrice,
        setDependentPrice,
        clearActivitiesForUserByIndex,
        clearFoodForUserByIndex,
        handleUserCheckboxToggle,
        selectedUsersForPayment,
        setPaymentMethod,
        clearBookingStateDefault,
        clearBookingState,
        addOrUpdateActivityItem,
        addManToBooking,
        addWomanToBooking,
        addJuniorToBooking,
        addKidToBooking,
        setPrimaryUser,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
