"use client";
import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/Kona-Logo.png";
import SelectInput from "../inputs/SelectInput";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonPrimaryOutline from "../buttons/ButtonPrimaryOutline";
import { NavLink, Link } from "react-router-dom";
import CustomArrow from "../inputs/CustomArrow";
import { Dropdown } from "antd";
import { useUser } from "../../contexts/UserContext";
import avatar from "../../assets/avatars/avatar3.webp";
import { useLanguage } from "../../contexts/LanguageContext";
import axiosInstance from "../../axios/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { ThreeDots } from "react-loader-spinner";
import Reveal from "../../shared/reveal/Reveal";
import BlurFade from "../../components/ui/blur-fade";
import CheckingExistingBookingModal from "../../components/existing-booking-checking/CheckingExistingBookingModal";

const Header = ({ picture }) => {
  const navigate = useNavigate();
  const mainNavRef = useRef(null);
  const hamburgerRef = useRef(null);
  const closeMenuRef = useRef(null);
  const location = useLocation();
  const { user, logout } = useUser();
  const { language, setLanguage } = useLanguage();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navigationData, setNavigationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userInitials = user
    ? user.name
        .split(" ")
        .map((n) => n[0])
        .join("")
    : "";

  // for small nav
  const [submenuItems, setSubmenuItems] = useState([]);
  const [secondaryDrawerTitle, setSecondaryDrawerTitle] = useState("");
  const [showSubmenu, setShowSubmenu] = useState(false);

  useEffect(() => {
    const getHeaderData = async () => {
      setIsLoading(true);

      try {
        const response = await axiosInstance.get("/lookup/menu", {
          params: {
            facility: "sports club",
          },
        });
        console.log("🚀 ~ getHeaderData ~ response:", response);

        const menuData = response.data.data.find(
          (item) => item.type === "main_header_menu"
        );
        if (menuData) {
          setNavigationData(menuData.items);
        } else {
          setNavigationData([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getHeaderData();
  }, [language]);

  const handleNavigation = (slug, id, isActivity = false) => {
    const path = isActivity ? `/activities/${slug}` : `/${slug}`;
    navigate(path, { state: { id: id } });
    closeMenu();
  };

  const openSubmenu = (submenu, parentSlug, title, isActivity = false) => {
    setSubmenuItems(
      submenu.map((subItem) => ({ ...subItem, isActivity })) // Add isActivity flag to submenu items
    );
    setSecondaryDrawerTitle(title);
    setShowSubmenu(true);
  };

  const closeSubmenu = () => {
    setShowSubmenu(false);
  };

  const renderNavItemsForSmallScreens = () => {
    return (
      <>
        {navigationData.map((item) => {
          const navPath = `/${item.slug}`;
          const isActive = isActiveLink(navPath);

          // Check if item has activities or pages
          if (item.activities && item.activities.length > 0) {
            return (
              <li
                className="header-title-show"
                key={item.id}
                onClick={() =>
                  openSubmenu(item.activities, item.slug, item.title.en, true)
                }
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  className="nav-item w-full flex justify-between items-center "
                >
                  {language === "en" ? item.title.en : item.title.ar}
                  <svg
                    className="self-center"
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 10.9894L6.15723 5.9947L1 1"
                      stroke="#1D1D1D"
                      strokeWidth="1.55004"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
            );
          } else if (item.pages && item.pages.length > 0) {
            return (
              <li
                className="header-title-show"
                key={item.id}
                onClick={() =>
                  openSubmenu(item.pages, item.slug, item.title.en)
                }
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  className="nav-item w-full flex justify-between items-center "
                >
                  {language === "en" ? item.title.en : item.title.ar}
                  <svg
                    className="self-center"
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 10.9894L6.15723 5.9947L1 1"
                      stroke="#1D1D1D"
                      strokeWidth="1.55004"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
            );
          } else {
            return (
              <NavLink
                className={
                  isActive
                    ? "text-[#fcd12a] border-b welcome-text border-[#fcd12a]"
                    : "hover:text-[#fcd12a] welcome-text text-black no-underline header-title-show"
                }
                to={navPath}
                state={{ id: item.id }}
                onClick={closeMenu}
                key={item.id}
              >
                {language === "en" ? item.title.en : item.title.ar}
              </NavLink>
            );
          }
        })}

        {/* Add the "Account" section for mobile view */}
        {user && (
          <li
            className="header-title-show"
            onClick={() =>
              openSubmenu(
                accountNavItems,
                "account",
                language === "en" ? "Account" : "حسابي"
              )
            }
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="nav-item w-full flex justify-between items-center "
            >
              {language === "en" ? "Account" : "حسابي"}
              <svg
                className="self-center"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10.9894L6.15723 5.9947L1 1"
                  stroke="#1D1D1D"
                  strokeWidth="1.55004"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </li>
        )}
      </>
    );
  };

  const renderSubmenuItems = () => {
    return (
      <ul>
        {submenuItems.map((subItem) => (
          <li
            key={subItem.id}
            onClick={(e) => {
              e.preventDefault();
              handleNavigation(subItem.slug, subItem.id, subItem.isActivity);
              closeSubmenu();
            }}
            className="kn-mobile-nav-item"
          >
            <a className="flex items-center justify-start gap-2 py-2 welcome-text">
              <span className="text-black text-[14px] font-medium leading-5 self-center w-full">
                {language === "en" ? subItem.title.en : subItem.title.ar}
              </span>
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const isActiveLink = (navPath) => {
    return location.pathname.startsWith(navPath);
  };

  const closeMenu = () => {
    mainNavRef.current.classList.remove("nav-open");
    setMobileMenuOpen(false);
    closeMenuRef.current.classList.add("hidden");
    hamburgerRef.current.classList.remove("hidden");
    document.body.style.overflow = "auto";
  };

  const renderSubmenu = (pages, parentSlug, hasActivities) => {
    let url;
    return pages.map((page) => {
      if (page.type === "activity") {
        url = `/activities/${page.slug}`;
      } else {
        url = parentSlug ? `/${parentSlug}/${page.slug}` : `/${page.slug}`;
      }
      return {
        key: page.id,
        label: (
          <Link
            to={url}
            state={{ id: page.id }}
            onClick={(e) => {
              closeMenu(e);
            }}
            className="flex items-start justify-start welcome-text gap-2 py-2"
          >
            <span className="text-black text-[14px] font-medium leading-5 self-center w-full">
              {language === "en" ? page.title.en : page.title.ar}
            </span>
          </Link>
        ),
      };
    });
  };

  const renderNavItems = () => {
    return navigationData.map((item, index) => {
      const navPath = `/${item.slug}`;
      const isActive = isActiveLink(navPath);

      if (item.pages.length > 0) {
        return (
          <Dropdown
            key={item.id}
            overlay={
              <Menu
                style={{ position: "relative", zIndex: 999999999 }}
                key={index}
                items={renderSubmenu(item.pages, item.slug)}
              />
            }
          >
            <NavLink
              className={
                isActive
                  ? "text-[#fcd12a] welcome-text border-b border-[#fcd12a]"
                  : "hover:text-[#fcd12a] welcome-text text-black no-underline"
              }
              to={navPath}
              state={{ id: item.id }}
              onClick={(e) => e.preventDefault()}
            >
              <div className="flex items-start justify-start welcome-text gap-2">
                {language === "en" ? item.title.en : item.title.ar}
                <CustomArrow />
              </div>
            </NavLink>
          </Dropdown>
        );
      } else if (item.activities && item.activities.length > 0) {
        let hasActivities = item.activities > 0;
        // Render activities in the navigation
        return (
          <Dropdown
            key={item.id}
            overlay={
              <Menu
                style={{ position: "relative", zIndex: 999999999 }}
                key={index}
                items={renderSubmenu(item.activities, item.slug, hasActivities)}
              />
            }
          >
            <NavLink
              className={
                isActive
                  ? "text-[#fcd12a] welcome-text border-b border-[#fcd12a]"
                  : "hover:text-[#fcd12a] welcome-text text-black no-underline"
              }
              to={navPath}
              state={{ id: item.id }}
              onClick={(e) => e.preventDefault()}
            >
              <div className="flex items-start justify-start gap-2 welcome-text">
                {language === "en" ? item.title.en : item.title.ar}
                <CustomArrow />
              </div>
            </NavLink>
          </Dropdown>
        );
      } else {
        return (
          <li key={item.id}>
            <NavLink
              to={navPath}
              state={{ id: item.id }}
              className={
                isActive
                  ? "welcome-text text-[#fcd12a] border-b border-[#fcd12a]"
                  : "text-black no-underline welcome-text"
              }
            >
              <span
                onClick={(e) => {
                  closeMenu(e);
                }}
                className="w-full"
              >
                {language === "en" ? item.title.en : item.title.ar}
              </span>
            </NavLink>
          </li>
        );
      }
    });
  };

  const toggleMobileMenu = () => {
    mainNavRef.current.classList.toggle("nav-open");
    setMobileMenuOpen(true);
    hamburgerRef.current.classList.add("hidden");
    closeMenuRef.current.classList.remove("hidden");
    document.body.style.overflow = "hidden";
  };

  const onLogout = () => {
    logout();
    closeMenu();
    navigate("/");
  };

  const accountNavItems = [
    {
      id: "my-booking",
      title: {
        en: "My Booking",
        ar: "حجزي",
      },
      slug: "account/my-booking",
    },
    {
      id: "account-settings",
      title: {
        en: "Account Settings",
        ar: "إعدادات الحساب",
      },
      slug: "account/account-details",
    },
  ];

  const options = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "العربيه",
      value: "ar",
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <header className="w-full h-[100px] bg-transparent flex items-center justify-center header-nav">
          <div className="container mx-auto">
            <div className="w-full flex items-start justify-between">
              <div className="flex items-start justify-start gap-[2rem]">
                <Link to="/">
                  <img
                    className="w-[180px] h-auto "
                    src={logo}
                    alt="website logo"
                  />
                </Link>

                <nav
                  ref={mainNavRef}
                  className="nav-desktop main-nav kn-nav-list self-center"
                >
                  <div className="container mx-auto">
                    <ul className="main-nav-list flex items-start justify-start gap-4">
                      {renderNavItems()}
                      {user ? (
                        <>
                          <div className="action-item-menu-hamburger w-full">
                            <Dropdown
                              overlay={
                                <Menu
                                  style={{
                                    position: "relative",
                                    zIndex: 999999999,
                                  }}
                                  key="account"
                                  items={accountNavItems}
                                />
                              }
                            >
                              <NavLink
                                className="text-black no-underline welcome-text"
                                to="/account"
                                onClick={(e) => e.preventDefault()}
                              >
                                <div className="flex items-start justify-start gap-2">
                                  {language === "en" ? "Account" : "حسابي"}
                                  <CustomArrow />
                                </div>
                              </NavLink>
                            </Dropdown>
                            <li>
                              <button
                                onClick={onLogout}
                                className="w-full flex items-start justify-start gap-3 py-[14px] px-[15px]"
                              >
                                <svg
                                  className="self-center"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="none"
                                  viewBox="0 0 19 19"
                                >
                                  <path
                                    fill="#EB5757"
                                    d="M10.748 5.786a.757.757 0 101.513 0v-.757a3.028 3.028 0 00-3.027-3.03H4.693a3.028 3.028 0 00-3.028 3.03v9.087a3.028 3.028 0 003.028 3.029h4.546a3.028 3.028 0 003.028-3.03v-.757a.757.757 0 10-1.514 0v.758c0 .836-.678 1.514-1.514 1.514H4.693a1.514 1.514 0 01-1.514-1.514V5.029c0-.837.678-1.515 1.514-1.515h4.54c.837 0 1.514.678 1.514 1.515v.757z"
                                  />
                                  <path
                                    fill="#EB5757"
                                    d="M14.532 10.33a.757.757 0 100-1.515H6.959a.757.757 0 000 1.515h7.573z"
                                  />
                                  <path
                                    fill="#EB5757"
                                    d="M13.24 7.836a.757.757 0 111.07-1.07l2.272 2.271a.757.757 0 010 1.071L14.31 12.38a.757.757 0 01-1.07-1.071l1.736-1.736-1.737-1.737z"
                                  />
                                </svg>
                                <span className="text-[#EB5757] text-[14px] md:welcome-text font-medium self-center">
                                  Logout
                                </span>
                              </button>
                            </li>
                          </div>
                        </>
                      ) : (
                        <li>
                          <Link
                            to="/login"
                            className="action-item-menu-hamburger w-full items-center justify-center mt-4"
                          >
                            <ButtonPrimaryOutline
                              style={{ width: "80%", margin: "0 auto" }}
                            >
                              {language === "en" ? "Login" : "تسجيل الدخول"}
                            </ButtonPrimaryOutline>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
                <nav
                  ref={mainNavRef}
                  className="nav-small-screens main-nav kn-nav-list self-center"
                >
                  <div className="container mx-auto">
                    {!showSubmenu ? (
                      <Reveal>
                        <ul className="main-nav-list flex flex-col items-start gap-4">
                          {renderNavItemsForSmallScreens()}

                          {user && (
                            <li>
                              <button
                                onClick={onLogout}
                                className="w-full flex items-start justify-start gap-3 py-[14px]"
                              >
                                <svg
                                  className="self-center"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="none"
                                  viewBox="0 0 19 19"
                                >
                                  <path
                                    fill="#EB5757"
                                    d="M10.748 5.786a.757.757 0 101.513 0v-.757a3.028 3.028 0 00-3.027-3.03H4.693a3.028 3.028 0 00-3.028 3.03v9.087a3.028 3.028 0 003.028 3.029h4.546a3.028 3.028 0 003.028-3.03v-.757a.757.757 0 10-1.514 0v.758c0 .836-.678 1.514-1.514 1.514H4.693a1.514 1.514 0 01-1.514-1.514V5.029c0-.837.678-1.515 1.514-1.515h4.54c.837 0 1.514.678 1.514 1.515v.757z"
                                  />
                                  <path
                                    fill="#EB5757"
                                    d="M14.532 10.33a.757.757 0 100-1.515H6.959a.757.757 0 000 1.515h7.573z"
                                  />
                                  <path
                                    fill="#EB5757"
                                    d="M13.24 7.836a.757.757 0 111.07-1.07l2.272 2.271a.757.757 0 010 1.071L14.31 12.38a.757.757 0 01-1.07-1.071l1.736-1.736-1.737-1.737z"
                                  />
                                </svg>
                                <span className="text-[#EB5757] text-[14px] md:welcome-text font-medium self-center">
                                  {language === "en"
                                    ? "Logout"
                                    : " تسجيل الخروج"}
                                </span>
                              </button>
                            </li>
                          )}

                          {!user && (
                            <li>
                              <Link
                                to="/login"
                                className="action-item-menu-hamburger w-full items-center justify-center mt-4"
                              >
                                <ButtonPrimaryOutline
                                  style={{ width: "80%", margin: "0 auto" }}
                                >
                                  {language === "en" ? "Login" : "تسجيل الدخول"}
                                </ButtonPrimaryOutline>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Reveal>
                    ) : (
                      <>
                        <div className="submenu-header">
                          <button
                            className="header-title-show w-full flex items-center justify-start gap-4 pb-[1rem] border-b border-[#EEEEEE]"
                            onClick={closeSubmenu}
                          >
                            <span>
                              <svg
                                className="self-center rtl:rotate-180"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.33284 10.3519C3.16099 10.5238 3.06445 10.7569 3.06445 11C3.06445 11.2431 3.16099 11.4762 3.33284 11.6481L8.51842 16.8337C8.60298 16.9212 8.70413 16.9911 8.81597 17.0391C8.92781 17.0871 9.04809 17.1124 9.16981 17.1135C9.29152 17.1145 9.41223 17.0914 9.52488 17.0453C9.63754 16.9992 9.73988 16.9311 9.82595 16.845C9.91202 16.759 9.98009 16.6566 10.0262 16.544C10.0723 16.4313 10.0955 16.3106 10.0944 16.1889C10.0933 16.0672 10.0681 15.9469 10.02 15.8351C9.97198 15.7232 9.90214 15.6221 9.81459 15.5375L6.19376 11.9167L18.3332 11.9167C18.5763 11.9167 18.8094 11.8201 18.9814 11.6482C19.1533 11.4763 19.2498 11.2431 19.2498 11C19.2498 10.7569 19.1533 10.5237 18.9814 10.3518C18.8094 10.1799 18.5763 10.0833 18.3332 10.0833L6.19376 10.0833L9.81459 6.46251C9.98157 6.28962 10.074 6.05807 10.0719 5.81773C10.0698 5.57738 9.97338 5.34747 9.80342 5.17751C9.63347 5.00755 9.40356 4.91115 9.16321 4.90906C8.92286 4.90697 8.69131 4.99937 8.51842 5.16634L3.33284 10.3519Z"
                                  fill="#2A2A2A"
                                />
                              </svg>
                            </span>
                            <span className="text-[#1D1D1D] text-[14px] font-semibold leading-5 self-center">
                              {language === "en"
                                ? "Back to Menu"
                                : " العوده الى القائمه"}
                            </span>
                          </button>
                          <h3 className="header-title-show my-[1rem] welcome-text font-semibold leading-6">
                            {secondaryDrawerTitle}
                          </h3>
                        </div>

                        {renderSubmenuItems()}
                      </>
                    )}
                  </div>
                </nav>
              </div>
              <div className="headerActions self-center flex items-start justify-start gap-[14px]">
                <SelectInput
                  options={options}
                  onChange={(e) => {
                    const newLanguage = e.value;
                    setLanguage(newLanguage);
                    localStorage.setItem("language", newLanguage);
                  }}
                  initialValue={language === "en" ? "English" : "العربيه"}
                />
                {/* <Link to={"/booking"}>
                  <ButtonPrimary>
                    {language === "en" ? "Book Activity" : "احجز نشاط"}
                  </ButtonPrimary>
                </Link> */}
                <CheckingExistingBookingModal button_type={"bookings"} />{" "}
                {/* Pass props */}
                {!user ? (
                  <Link to="/login">
                    <ButtonPrimaryOutline>
                      {language === "en" ? "Login" : "تسجيل الدخول"}
                    </ButtonPrimaryOutline>
                  </Link>
                ) : (
                  <Link to="/account">
                    <button className="w-[46px] h-[46px] p-2 rounded-full overflow-hidden flex items-center justify-center border border-[#FCD12A] bg-[#fcd22a27]">
                      <span className="text-[#FCD12A] font-medium text-[18px]">
                        {/* {userInitials} */}
                        {picture ? (
                          <img
                            src={picture}
                            alt="user profile "
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          userInitials
                        )}
                      </span>
                    </button>
                  </Link>
                )}
              </div>

              <div className="mob-nav flex items-start justify-start gap-[1.6rem] self-center">
                <SelectInput
                  options={options}
                  onChange={(e) => {
                    const newLanguage = e.value;
                    setLanguage(newLanguage);
                    localStorage.setItem("language", newLanguage);
                  }}
                  initialValue={language === "en" ? "English" : "العربيه"}
                />
                <button
                  className="hamburger self-center"
                  ref={hamburgerRef}
                  onClick={toggleMobileMenu}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 8.79327H21M3 15.7933H21"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <button
                  onClick={closeMenu}
                  ref={closeMenuRef}
                  className="hidden flex items-start justify-start gap-[1rem] self-center"
                >
                  <svg
                    className="self-center"
                    width="19"
                    height="18"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 9.02786L1.73459 14.2933L0 12.5587L5.26541 7.29327L0 2.02786L1.73459 0.293274L7 5.55868L12.2654 0.293274L13.1327 1.16057L14 2.02786L8.73459 7.29327L14 12.5587L12.2654 14.2933L7 9.02786Z"
                      fill="#2A2A2A"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
