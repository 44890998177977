import React from 'react'

const ArrowDown = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 20 21"
            >
                <path
                    fill="#2A2A2A"
                    fillRule="evenodd"
                    d="M10.331 13.552a.469.469 0 01-.663 0L4.89 8.773a.469.469 0 010-.663l.221-.22a.469.469 0 01.663 0L10 12.115l4.226-4.226a.469.469 0 01.663 0l.221.22a.469.469 0 010 .663l-4.779 4.78z"
                    clipRule="evenodd"
                ></path>
            </svg>

        </>
    )
}

export default ArrowDown
