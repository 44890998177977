import React, { useState } from "react";
import { Button, message, Upload } from "antd";
import "react-tabs/style/react-tabs.css";
import AccountInfoItem from "./AccountInfoItem";
import FullNameModal from "./modals/fullNameModal";
import EmailModal from "./modals/EmailModal";
import PhoneModal from "./modals/PhoneModal";
import PasswordModal from "./modals/PassowrdModal";
import DateOfBirthModal from "./modals/DateOfBirthModal";
import IdNumberModal from "./modals/IdNumberModal";
import { useUser } from "../../../contexts/UserContext";
import { useLanguage } from "../../../contexts/LanguageContext";
import BlurFade from "../../ui/blur-fade";

const AccountSettingsInfo = () => {
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [picture, setPicture] = useState(null);
  const { user } = useUser();
  const userInitials = user
    ? user.name
        .split(" ")
        .map((n) => n[0])
        .join("")
    : "";

  const { language } = useLanguage();

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".png, .jpg, .jpeg, .webp",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setUploadedImageFile(info.file.originFileObj);
        setPicture(info.file.originFileObj);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        setPicture(null);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const labels = {
    en: {
      fullName: "Full Name",
      email: "Email",
      phoneNumber: "Phone Number",
      dateOfBirth: "Date of Birth",
      password: "Password",
      idInformation: "ID Information",
    },
    ar: {
      fullName: "الاسم الكامل",
      email: "البريد الإلكتروني",
      phoneNumber: "رقم الهاتف",
      dateOfBirth: "تاريخ الميلاد",
      password: "كلمة السر",
      idInformation: "معلومات الهوية",
    },
  };

  const accountInfoData = [
    {
      accLabel: labels[language].fullName,
      accValue: user?.name,
      Modal: FullNameModal,
    },
    {
      accLabel: labels[language].email,
      accValue: user?.email,
      Modal: EmailModal,
    },
    {
      accLabel: labels[language].phoneNumber,
      accValue: user?.phone,
      Modal: PhoneModal,
    },
    {
      accLabel: labels[language].dateOfBirth,
      accValue: user?.birthdate,
      Modal: DateOfBirthModal,
    },
    {
      accLabel: labels[language].password,
      accValue: "•••••••••••••••••",
      Modal: PasswordModal,
    },
    {
      accLabel: labels[language].idInformation,
      accValue: user?.identity_card_number,
      Modal: IdNumberModal,
    },
  ];
  console.log("picture", picture);

  return (
    <>
      <section className="w-full h-auto">
        <BlurFade delay={0.06} inView>
          <h1 className="text-[#2A2A2A] text-[28px] font-semibold leading-7">
            {language === "en" ? "Account Settings" : "إعدادات الحساب"}
          </h1>
        </BlurFade>

        <BlurFade delay={0.08} inView>
          <form className="account-setting-photo-upload w-full my-[2rem] flex items-start justify-start gap-3">
            <div className="w-[65px] h-[65px] py-3 rounded-full overflow-hidden flex items-center justify-center border border-[#FCD12A] bg-[#fcd22a27]">
              <span className="text-[#FCD12A] font-medium text-[24px]">
                {userInitials}
              </span>
            </div>
            <Upload className="self-center " {...props}>
              <Button className="bg-[#ECECEC] py-2 text-black font-medium text-[14px] h-auto">
                {language === "en"
                  ? " Edit Profile Photo"
                  : "تعديل الصوره الشخصيه"}
              </Button>
            </Upload>
          </form>
        </BlurFade>

        <div className="w-full h-auto rounded-[8px] border border-[#ECECEC] p-4">
          <BlurFade delay={0.1} inView>
            <h3 className="text-[#000000] text-[16px] leading-6 font-semibold mt-2 mb-5">
              {language === "en" ? "Personal Information" : "معلومات شخصية"}
            </h3>
          </BlurFade>

          {/* Rendering AccountInfoItem dynamically */}
          <div className="account-info-list">
            {accountInfoData.map((info, index) => (
              <BlurFade
                className={`account-setting-info-item w-full`}
                delay={index * 0.04}
                inView
                key={index}
              >
                <AccountInfoItem
                  accLabel={info.accLabel}
                  accValue={info.accValue}
                  Modal={info.Modal}
                />
              </BlurFade>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountSettingsInfo;
