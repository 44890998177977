import React, { useEffect } from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { useBooking } from "../../contexts/BookingContext";
import { useUser } from "../../contexts/UserContext";

// Payment Method Selection Component
const PaymentMethodSelection = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  totalPaymentPrice,
}) => {

  const { language } = useLanguage();
  const { user } = useUser();
  const { setPaymentMethod } = useBooking();

  // Set the default payment method based on total payment price
  useEffect(() => {
    if (
      totalPaymentPrice === 0 &&
      selectedPaymentMethod !== "Membership Credit"
    ) {
      setSelectedPaymentMethod("Membership Credit");
      setPaymentMethod("Membership Credit");
    } else if (totalPaymentPrice > 0 && !selectedPaymentMethod) {
      // Dynamically select the first available method if not already selected
      setSelectedPaymentMethod(paymentMethods[0]?.value);
      setPaymentMethod(paymentMethods[0]?.value);
    }
  }, [
    totalPaymentPrice,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setPaymentMethod,
    paymentMethods,
  ]);

  // Handle payment method selection change (only if selection is enabled)
  const handlePaymentMethodSelection = (method) => {
    setSelectedPaymentMethod(method); // Update local state
    setPaymentMethod(method); // Update the booking context
  };

  // Get the appropriate icon based on the selected payment method
  const getPaymentIcon = (method) => {
    return selectedPaymentMethod === method.value
      ? method.activeIcon
      : method.icon;
  };

  if (totalPaymentPrice === 0 && user?.user_type !== "athlete") {
    return null;
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-4">
      <h3 className="text-[#1E1E1E] text-[16px] md:text-[18px] font-semibold leading-7">
        {language === "en" ? "Select Payment Method" : "اختر طريقة الدفع"}
      </h3>
      <div className="w-full flex flex-col items-start justify-start gap-4">
        {paymentMethods.map((type, index) => (
          <div
            key={index}
            className={`kn-payment-type ${
              selectedPaymentMethod === type.value
                ? type.value === "applepay"
                  ? "kn-payment-type-apple-active" // Apply Apple Pay-specific active styles
                  : "kn-payment-type-active"
                : ""
            }`}
          >
            <label className={`kn-payment-type-radio `}>
              <div className="flex items-start justify-start gap-3">
                {/* Display payment method icon */}
                <img
                  className="self-center"
                  src={getPaymentIcon(type)}
                  alt={`${type.value} icon`}
                />
                <span
                  className={`self-center ${
                    selectedPaymentMethod === "applepay" &&
                    type.value === "applepay"
                      ? "text-[#FFFFFF]" // Example: Text color for Apple Pay when active
                      : ""
                  }`}
                >
                  {type?.name[language]}
                </span>{" "}
              </div>
              <input
                className="self-center"
                type="radio"
                name={`paymentType${type.id}`}
                value={type.value}
                checked={selectedPaymentMethod === type.value}
                onChange={() => handlePaymentMethodSelection(type.value)}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodSelection;
