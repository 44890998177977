import React from "react";

const ButtonPrimaryOutline = ({
  type = "button",
  style,
  className,
  children,
  ...props
}) => {
  return (
    <>
      <button
        type={type}
        style={style}
        className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-transparent border border-black
                py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-black
                 font-medium leading-6 text-[12px] md:text-[16px] hover:bg-black hover:text-white transition-all duration-200
                  cursor-pointer xs:min-w-[80px] xs:py-[7px] xs:px-[14px] ${className} disabled:cursor-not-allowed disabled:hover:bg-none`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default ButtonPrimaryOutline;
