import React, { useState, useEffect } from "react";
import Input from "../../../shared/inputs/Input";
import { useBooking } from "../../../contexts/BookingContext";
import { useLanguage } from "../../../contexts/LanguageContext";
import MaleIcon from "../../icons/MaleIcon";
import MaleIconMain from "../../icons/MaleIconMain";
import FemaleIconMain from "../../icons/FemaleIconMain";
import FemaleIcon from "../../icons/FemaleIcon";
import { useUser } from "../../../contexts/UserContext";

const GuestForm = React.memo(({ user, index, memberType, errors }) => {
  const { language } = useLanguage();
  const {
    updateCurrentUser,
    booking,
    removeBookingUser,
    setPrimaryUser,
    updateBookingSummary,
  } = useBooking();

  const { user: userBooking } = useUser();
  // Determine if this form represents the logged-in user (assumed to be at index 0)
  const isLoggedInUser = index === 0 && userBooking;

  // Initialize states for user details
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [whatsappNumberError, setWhatsappNumberError] = useState(false);
  const [whatsappNumberErrorMessage, setWhatsappNumberErrorMessage] =
    useState("");

  // Effect to initialize field values from `booking_users` based on `index`
  useEffect(() => {
    setFirstName(booking?.booking_users[index]?.first_name || "");
    setLastName(booking?.booking_users[index]?.last_name || "");
    setEmail(booking?.booking_users[index]?.email || "");
    setWhatsappNumber(booking?.booking_users[index]?.phone || "");
  }, [booking.booking_users, index]);

  // Combine first and last name for display
  const fullName = `${firstName} ${lastName}`.trim();

  // Effect to handle changes in the errors prop
  useEffect(() => {
    if (errors) {
      setFirstNameError(!!errors?.[`booking_users.${index}.first_name`]);
      setFirstNameErrorMessage(
        errors?.[`booking_users.${index}.first_name`]?.[0] || ""
      );

      setLastNameError(!!errors?.[`booking_users.${index}.last_name`]);
      setLastNameErrorMessage(
        errors?.[`booking_users.${index}.last_name`]?.[0] || ""
      );

      setEmailError(!!errors?.[`booking_users.${index}.email`]);
      setEmailErrorMessage(errors?.[`booking_users.${index}.email`]?.[0] || "");

      setWhatsappNumberError(!!errors?.[`booking_users.${index}.phone`]);
      setWhatsappNumberErrorMessage(
        errors?.[`booking_users.${index}.phone`]?.[0] || ""
      );
    }
  }, [errors, index]);

  // Function to update field data
  const updateFieldData = (field, value) => {
    const updatedUser = { ...booking.booking_users[index], [field]: value };
    updateCurrentUser(index, updatedUser);
  };

  // Handle first name validation on blur
  const handleFirstNameBlur = () => {
    if (!firstName && booking.booking_users[index]?.member_type !== "nanny") {
      setFirstNameError(true);
      setFirstNameErrorMessage("First name is required");
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");
      updateFieldData("first_name", firstName);
    }
    updateBookingSummary();
  };

  // Handle last name validation on blur
  const handleLastNameBlur = () => {
    if (!lastName) {
      setLastNameError(true);
      setLastNameErrorMessage("Last name is required");
    } else {
      setLastNameError(false);
      setLastNameErrorMessage("");
      updateFieldData("last_name", lastName);
    }
    updateBookingSummary();
  };

  // Handle email validation on blur
  const handleEmailBlur = () => {
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
      updateFieldData("email", email);
    }
  };

  // Handle WhatsApp number validation and update summary on blur
  const handleWhatsappNumberBlur = () => {
    if (!whatsappNumber) {
      setWhatsappNumberError(true);
      setWhatsappNumberErrorMessage("Whatsapp number is required");
    } else {
      setWhatsappNumberError(false);
      setWhatsappNumberErrorMessage("");
      updateFieldData("phone", whatsappNumber);
    }
  };

  // Filter booking_users by memberType to get the correct display index
  const filteredUsers = booking.booking_users.filter(
    (userBooking) => userBooking.gender === user.gender
  );

  // Calculate display index within the filtered list
  const displayIndex =
    filteredUsers.findIndex(
      (filteredUser) => filteredUser === booking.booking_users[index]
    ) + 1;

  return (
    <div className="kn-booking-info">
      <div className="w-full flex items-start justify-between mb-[2rem]">
        <span
          className={`w-auto inline-block ${
            user.primary === false ? "bg-[#EBEBEB]" : "bg-[#FCD12A14]"
          } py-[8px] px-[16px] rounded-[8px]`}
        >
          {user.gender === "male" ? (
            user.primary === true ? (
              <MaleIconMain />
            ) : (
              <MaleIcon />
            )
          ) : user.primary === true ? (
            <FemaleIconMain />
          ) : (
            <FemaleIcon />
          )}
          <span
            className={`${
              user.primary === !true
                ? "text-[#2A2A2A] font-medium"
                : "text-[#FCD12A] font-semibold"
            } text-[14px] leading-5 self-center`}
          >
            {user?.first_name && user?.last_name
              ? `${user.first_name} ${user.last_name} ${
                  user.primary === true ? "(You)" : ""
                }`
              : user.gender === "male"
              ? `Man ${displayIndex} ${user.primary === true ? "(You)" : ""}`
              : `Woman ${displayIndex} ${user.primary === true ? "(You)" : ""}`}
          </span>
        </span>
        <div className="self-center flex items-center justify-start gap-6">
          <div className="flex items-start justify-start gap-2 self-center">
            {/* {!userBooking && (
              <label>
                <input
                  type="radio"
                  name="primary-input-user"
                  checked={user.primary}
                  onChange={() => setPrimaryUser(index)}
                  disabled={user.primary === true}
                  className="primaryRadioInput self-center"
                />
              </label>
            )} */}

            {/* This Checks:
                If userBooking exists, it only displays the text when index === 0.
                If userBooking does not exist, it displays the text for all users by default.
             */}
            {/* {user.primary === true && (
              <p
                className={`${
                  user.primary ? "text-[#FCD12A]" : "text-[#282828]"
                } text-[14px] leading-4 font-medium self-center`}
              >
                Selected as Main Guest
              </p>
            )} */}
          </div>
          {index !== 0 && (
            <button
              className="self-center"
              onClick={() => removeBookingUser(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="24"
                fill="none"
                viewBox="0 0 23 24"
              >
                <g fill="#E63232">
                  <path d="M12.275 11.879l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.955a.626.626 0 10.882.882l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
                  <path d="M11.267 21.894a10.016 10.016 0 110-20.032 10.016 10.016 0 010 20.032zm0-18.779a8.763 8.763 0 100 17.527 8.763 8.763 0 000-17.527z"></path>
                </g>
              </svg>
            </button>
          )}
        </div>
      </div>
      <form className="w-full h-auto mt-[2rem]">
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID={`first-name-input-${index}`}
            type="text"
            name="firstnameInput"
            placeholder={
              language === "en" ? "Enter your first name" : "ادخل الاسم الأول"
            }
            label={language === "en" ? "First Name" : "الاسم الأول"}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              // updateFieldData("first_name", e.target.value);
            }}
            error={firstNameError}
            errorMessage={firstNameErrorMessage}
            redStar={true}
            onBlur={handleFirstNameBlur}
            disabled={isLoggedInUser} // Disable if this is the logged-in user
          />
          <Input
            ID={`last-name-input-${index}`}
            type="text"
            name="lastnameInput"
            placeholder={
              language === "en" ? "Enter your last name" : "ادخل الاسم الأخير"
            }
            label={language === "en" ? "Last Name" : "الاسم الأخير"}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              // updateFieldData("last_name", e.target.value);
            }}
            error={lastNameError}
            errorMessage={lastNameErrorMessage}
            redStar={true}
            onBlur={handleLastNameBlur}
            disabled={isLoggedInUser} // Disable if this is the logged-in user
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID={`email-input-${index}`}
            type="email"
            name="emailInput"
            placeholder={
              language === "en"
                ? "Enter your email address"
                : "ادخل البريد الإلكتروني"
            }
            label={language === "en" ? "Email" : "البريد الإلكتروني"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={emailError}
            errorMessage={emailErrorMessage}
            redStar={user.primary === true ? true : false}
            onBlur={handleEmailBlur}
          />
          <Input
            ID={`whatsappNumber-input-${index}`}
            type="text"
            name="whatsappNumberInput"
            placeholder={
              language === "en"
                ? "Enter your WhatsApp number"
                : "ادخل رقم الواتساب"
            }
            label={language === "en" ? "Whatsapp Number" : "رقم الواتساب"}
            value={whatsappNumber}
            onChange={(e) => {
              setWhatsappNumber(e.target.value);
            }}
            error={whatsappNumberError}
            errorMessage={whatsappNumberErrorMessage}
            redStar={true}
            onBlur={handleWhatsappNumberBlur}
          />
        </div>
      </form>
    </div>
  );
});

export default GuestForm;
