import React, { useState } from "react";
import { Modal } from "antd";
import Input from "../../../../shared/inputs/Input";
import ButtonPrimary from "../../../../shared/buttons/ButtonPrimary";
import ButtonWhite from "../../../../shared/buttons/ButtonWhite";
import axiosInstance from "../../../../axios/axiosInstance";
import { useUser } from "../../../../contexts/UserContext";
import { notification } from "antd";
import { useLanguage } from "../../../../contexts/LanguageContext";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const EmailModal = ({ isModalOpen, handleCancel, handleOk }) => {
  const { language } = useLanguage();
  const [api, contextHolder] = notification.useNotification();
  const { user, updateUser } = useUser();
  const [email, setEmail] = useState();
  console.log("🚀 ~ EmailModal ~ email:", email);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleErrors = (errors) => {
    // Clear all previous errors
    setEmailError(false);
    setEmailErrorMessage("");
    setPasswordError(false);
    setPasswordErrorMessage("");
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const message = errors[key][0];
        switch (key) {
          case "password":
            setIsSuccess(false);
            setErrorMessage(message);
            setPasswordError(true);
            break;
          case "email":
            setIsSuccess(false);
            setEmailError(true);
            setErrorMessage(message);
            break;
          default:
            setIsSuccess(false);
            setErrorMessage(message);
            break;
        }
      }
    }
  };

  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message,
      description: null,
      placement: "top",
      className: "mb-0",
    });
  };

  const onUpdateEmail = async () => {
    const phoneNumberData = parsePhoneNumberFromString(user.phone);
    console.log("🚀 ~ onUpdateEmail ~ phoneNumberData:", phoneNumberData);
    // const { countryCallingCode, nationalNumber } = phoneNumberData;
    try {
      const response = await axiosInstance.post(`user/profile`, {
        first_name: user?.first_name,
        last_name: user?.last_name,
        phone_code: user?.phone_code,
        phone: user?.phone,
        birthdate: user?.birthdate,
        email: email,
        identity_card_number: user?.identity_card_number,
        identity_card_type: user?.identity_card_type,
        password: password,
        lang: language,
      });

      console.log("respose", response);
      if (response.status === 200) {
        updateUser(response.data.data);
        setIsSuccess(true);
        openNotificationWithIcon(
          "success",
          language === "en"
            ? "Email Address Updated Successfully"
            : "تم تحديث الايميل بنجاح"
        );
        // reset errors and reset form
        setEmail("");
        setPassword("");
        setEmailError(false);
        setEmailErrorMessage("");
        setPasswordError(false);
        setPasswordErrorMessage("");
      }
    } catch (error) {
      setIsSuccess(false);
      console.log("error", error);
      openNotificationWithIcon("error", language === "en" ? "Failed to Update Email Address" : "فشل تحديث الايميل");
      handleErrors(error.response.data.errors);
      //   setErrorMessage(error.response.data.message);
    }
  };

  const modifiedHandleOk = async () => {
    // validate the inputs
    if (!email && !password) {
      setEmailError(true);
      language === "en"
        ? setEmailErrorMessage("Email is required")
        : setEmailErrorMessage("الايميل مطلوب");
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Password is required")
        : setPasswordErrorMessage("الرقم السري مطلوب");
      return;
    }
    if (!email) {
      setEmailError(true);
      language === "en"
        ? setEmailErrorMessage("Email is required")
        : setEmailErrorMessage("الايميل مطلوب");
      return;
    }
    if (!password) {
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Password is required")
        : setPasswordErrorMessage("الرقم السري مطلوب");
      return;
    }

    setEmailError(false);
    setEmailErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");

    await onUpdateEmail(); // Wait for this function to finish

    handleOk({ email, password, isSuccess });
  };

  const validateForm = (emailInput, passwordInput) => {
    if (emailInput && passwordInput.length >= 6) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  // func to disable save changes btn if entered email is the same as the current email and set the email state
  const onChangeEmail = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    validateForm(emailInput, password);
  };

  const onChangePassword = (e) => {
    const passwordInput = e.target.value;
    setPassword(passwordInput);
    validateForm(email, passwordInput);
  };

  return (
    <>
      {contextHolder}
      <Modal footer={null} open={isModalOpen} closeIcon={null}>
        <div className="w-full flex items-start justify-between">
          <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
            {language === "en" ? "Change Email" : "تغير الايميل"}
          </h3>
          <button onClick={handleCancel} className="self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="none"
              viewBox="0 0 23 23"
            >
              <g fill="#E63232" clipPath="url(#clip0_366_2657)">
                <path d="M12.275 11.267l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.954a.625.625 0 10.882.883l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
                <path d="M11.267 21.281a10.015 10.015 0 110-20.03 10.015 10.015 0 010 20.03zm0-18.778a8.763 8.763 0 100 17.526 8.763 8.763 0 000-17.526z"></path>
              </g>
              <defs>
                <clipPath id="clip0_366_2657">
                  <path fill="#fff" d="M0 0H22.534V22.534H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <form className="w-full mt-[2rem]">
          <Input
            ID="email-input"
            type="email"
            name="emailInput"
            placeholder={
              language === "en" ? "Enter your email" : "ادخل الايميل"
            }
            label={language === "en" ? "New Email" : "الايميل الجديد"}
            value={email}
            onChange={onChangeEmail}
            error={emailError}
            errorMessage={emailErrorMessage}
          />
          <Input
            ID="password-input"
            type="password"
            name="phoneInput"
            placeholder={
              language === "en"
                ? "Enter your password"
                : "ادخل كلمه المرور الحاليه"
            }
            label={
              language === "en"
                ? "Current Password"
                : "ادخل كلمه المرور الحاليه"
            }
            value={password}
            onChange={onChangePassword}
            error={passwordError}
            errorMessage={passwordErrorMessage}
          />
          {isSuccess === false && (
            <div className="kn-error-wrapper mb-6">
              <div className="w-full flex items-start justify-start gap-2">
                <div className="self-center w-[25px] h-[25px]">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.25C7.66498 16.25 6.35994 15.8541 5.2499 15.1124C4.13987 14.3707 3.27471 13.3165 2.76382 12.0831C2.25292 10.8497 2.11925 9.49251 2.3797 8.18314C2.64015 6.87377 3.28303 5.67104 4.22703 4.72703C5.17104 3.78303 6.37377 3.14015 7.68314 2.8797C8.99251 2.61925 10.3497 2.75292 11.5831 3.26382C12.8165 3.77471 13.8707 4.63987 14.6124 5.7499C15.3541 6.85994 15.75 8.16498 15.75 9.5C15.748 11.2896 15.0362 13.0054 13.7708 14.2708C12.5054 15.5362 10.7896 16.248 9 16.25ZM9 4.1C7.93198 4.1 6.88795 4.41671 5.99992 5.01007C5.1119 5.60343 4.41977 6.44679 4.01105 7.43351C3.60234 8.42023 3.4954 9.50599 3.70376 10.5535C3.91212 11.601 4.42642 12.5632 5.18163 13.3184C5.93683 14.0736 6.89902 14.5879 7.94651 14.7962C8.99401 15.0046 10.0798 14.8977 11.0665 14.489C12.0532 14.0802 12.8966 13.3881 13.4899 12.5001C14.0833 11.6121 14.4 10.568 14.4 9.5C14.3984 8.06833 13.8289 6.69575 12.8166 5.6834C11.8043 4.67105 10.4317 4.10161 9 4.1Z"
                      fill="#C81E1E"
                    />
                    <path
                      d="M9.95445 9.5L11.5022 7.95223C11.6252 7.82492 11.6932 7.65441 11.6917 7.47743C11.6901 7.30045 11.6192 7.13115 11.494 7.006C11.3689 6.88085 11.1996 6.80986 11.0226 6.80832C10.8456 6.80678 10.6751 6.87482 10.5478 6.99778L9 8.54555L7.45223 6.99778C7.32492 6.87482 7.15441 6.80678 6.97743 6.80832C6.80045 6.80986 6.63115 6.88085 6.506 7.006C6.38085 7.13115 6.30986 7.30045 6.30832 7.47743C6.30678 7.65441 6.37482 7.82492 6.49778 7.95223L8.04555 9.5L6.49778 11.0478C6.43331 11.11 6.38188 11.1845 6.34651 11.2669C6.31113 11.3492 6.29251 11.4378 6.29173 11.5274C6.29095 11.6171 6.30803 11.7059 6.34197 11.7889C6.37591 11.8719 6.42603 11.9472 6.48941 12.0106C6.55279 12.074 6.62815 12.1241 6.71111 12.158C6.79406 12.192 6.88295 12.209 6.97257 12.2083C7.0622 12.2075 7.15077 12.1889 7.23313 12.1535C7.31548 12.1181 7.38996 12.0667 7.45223 12.0022L9 10.4545L10.5478 12.0022C10.6751 12.1252 10.8456 12.1932 11.0226 12.1917C11.1996 12.1901 11.3689 12.1192 11.494 11.994C11.6192 11.8689 11.6901 11.6996 11.6917 11.5226C11.6932 11.3456 11.6252 11.1751 11.5022 11.0478L9.95445 9.5Z"
                      fill="#C81E1E"
                    />
                  </svg>
                </div>

                <p className="kn-error self-center">{errorMessage}</p>
              </div>
            </div>
          )}

          <div className="w-full flex items-start justify-start gap-3 my-4">
            <ButtonPrimary disabled={isDisabled} onClick={modifiedHandleOk}>
              {language === "en" ? "Save Changes" : "حفظ التغييرات"}
            </ButtonPrimary>
            <ButtonWhite onClick={handleCancel}>
              {language === "en" ? "Cancel" : "الغاء"}
            </ButtonWhite>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EmailModal;
