import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "./UserContext"; // Assuming you have UserContext for user data

const BookingTrackingContext = createContext();

export const useBookingTracking = () => useContext(BookingTrackingContext);

export const BookingTrackingProvider = ({ children }) => {
  const location = useLocation();
  const { user } = useUser(); // Get the current user

  // Logic to update the tracking when the location changes between specific pages
  useEffect(() => {
    const savedState = loadTrackingState();
    const newCurrentPage = location.pathname;
    const newPreviousPage = savedState.currentPage || null;

    if (newCurrentPage === "/booking-information") {
      setCurrentPage(newCurrentPage);
      setPreviousPage("/booking");
      saveTrackingState(newCurrentPage, "/booking");
    } else if (newCurrentPage === "/payment") {
      setCurrentPage(newCurrentPage);
      setPreviousPage("/booking-information");
      saveTrackingState(newCurrentPage, "/booking-information");
    }

    // Handle /booking initialization without modifying previousPage
  }, [location.pathname, user]);

  // Retrieve tracking data from localStorage based on user ID or fallback key
  const loadTrackingState = () => {
    const savedTracking = JSON.parse(
      localStorage.getItem("bookingTracking") || "{}"
    );

    // Use 'kona_currentTracking' if no user is logged in
    const trackingKey = user?.id || "kona_currentTracking";

    return (
      savedTracking[trackingKey] || {
        currentPage: location.pathname, // Default to the current path if no tracking exists
        previousPage: null,
      }
    );
  };

  // Function to save tracking data to localStorage
  const saveTrackingState = (currentPage, previousPage) => {
    // Use 'kona_currentTracking' if no user is logged in
    const trackingKey = user?.id || "kona_currentTracking";

    const savedTracking = JSON.parse(
      localStorage.getItem("bookingTracking") || "{}"
    );
    savedTracking[trackingKey] = { currentPage, previousPage };

    localStorage.setItem("bookingTracking", JSON.stringify(savedTracking));
  };

  // Initialize state with saved data or default values
  const [currentPage, setCurrentPage] = useState(
    loadTrackingState().currentPage
  );
  const [previousPage, setPreviousPage] = useState(
    loadTrackingState().previousPage
  );

  // Function to update the current and previous pages
  const updatePages = (newPage) => {
    setPreviousPage(currentPage);
    setCurrentPage(newPage);
    saveTrackingState(newPage, currentPage); // Save updated tracking for the current user
  };

  const clearTrackingState = () => {
    // Completely remove bookingTracking from localStorage
    localStorage.removeItem("bookingTracking");

    // Clear the state in your context
    setCurrentPage(null);
    setPreviousPage(null);
  };

  return (
    <BookingTrackingContext.Provider
      value={{ currentPage, previousPage, updatePages, clearTrackingState }}
    >
      {children}
    </BookingTrackingContext.Provider>
  );
};
