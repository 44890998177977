import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "./UserContext";
const TicketContext = createContext();

export const TicketProvider = ({ children }) => {
  const { user } = useUser();
  const [ticketData, setTicketData] = useState(() => {
    // Initialize state from localStorage if it exists, or use default values
    const savedData = localStorage.getItem("ticketData");
    return savedData
      ? JSON.parse(savedData)
      : {
          event_id: null,
          phone: "",
          email: "",
          name: "",
          payment_method: "",
          booking_tickets: [],
        };
  });

  // Watch for user changes and update ticketData
  useEffect(() => {
    if (user) {
      setTicketData((prevState) => ({
        ...prevState,
        name: user.name || "",
        email: user.email || "",
        phone: user.phone_code ? `${user.phone_code}${user.phone}` : "",
      }));
    }
  }, [user]); // This ensures ticketData updates when user changes


  // Persist to localStorage whenever ticketData changes
  useEffect(() => {
    localStorage.setItem("ticketData", JSON.stringify(ticketData));
  }, [ticketData]);

  const updateTicketQuantity = (ticketId, quantity) => {
    setTicketData((prevState) => {
      const updatedTickets = [...prevState.booking_tickets];
      const ticketIndex = updatedTickets.findIndex(
        (t) => t.event_ticket_id === ticketId
      );

      if (ticketIndex > -1) {
        if (quantity === 0) {
          updatedTickets.splice(ticketIndex, 1); // Remove ticket if quantity is 0
        } else {
          updatedTickets[ticketIndex].quantity = quantity;
        }
      } else if (quantity > 0) {
        updatedTickets.push({ event_ticket_id: ticketId, quantity });
      }

      return { ...prevState, booking_tickets: updatedTickets };
    });
  };

  const updatePaymentMethod = (method) => {
    setTicketData((prevState) => ({ ...prevState, payment_method: method }));
  };

  const updateUserData = (key, value) => {
    setTicketData((prevState) => ({ ...prevState, [key]: value }));
  };

  const updateEventId = (newEventId) => {
    setTicketData((prevState) => ({
      ...prevState,
      event_id: newEventId,
    }));
  };

  const clearTicketData = () => {
    // Reset the state and clear localStorage
    const defaultState = {
      event_id: null,
      phone: "",
      email: "",
      name: "",
      payment_method: "",
      booking_tickets: [],
    };
    setTicketData(defaultState);
    localStorage.removeItem("ticketData");
  };
  const clearTicketsOnly = () => {
    setTicketData((prevState) => ({
      ...prevState,
      event_id: null,
      booking_tickets: [],
      payment_method: "",
    }));
  };
  

  return (
    <TicketContext.Provider
      value={{
        ticketData,
        updateTicketQuantity,
        updatePaymentMethod,
        updateUserData,
        clearTicketData,
        updateEventId,
        clearTicketsOnly
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export const useTicketContext = () => useContext(TicketContext);
