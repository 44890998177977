import React, { useState, useCallback, useEffect } from "react";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { useLanguage } from "../../../contexts/LanguageContext";
import { Drawer } from "antd";
import QuantityInput from "../../../shared/quantity/QuantityInput";
import { useBooking } from "../../../contexts/BookingContext";
import { useUpdateBooking } from "../../../contexts/UpdateBookingContext";
import { v4 as uuidv4 } from "uuid";
import SaudiSymbol from "../../../components/currency/SaudiSymbol";
export const FoodItem = React.memo(
  ({
    data,
    onCloseMealsDrawer,
    onOpenMealsDrawer,
    currentIndex,
    currentUserType,
    user = null,
    isUpdating = false,
    type,
  }) => {
    console.log("🚀 ~ type:", type)
    const { addOrUpdateFoodItem, booking, removeFoodItem } = useBooking();
    const { updateBookingData, addFoodToUser, clearUserFoodItemById } =
      useUpdateBooking();
    const { language, translate } = useLanguage();
    const [foodQuantityDrawer, setFoodQuantityDrawer] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [selectedItemId, setSelectedItemId] = useState(null);

    // New state to hold the selected options for each variation
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [selectedSides, setSelectedSides] = useState([]);
    const [isFoodItemselected, setIsFoodItemselected] = useState(false);
    const [FoodItemQuantity, setFoodItemQuantity] = useState(false);

    const handleExtraChange = useCallback((extraId) => {
      setSelectedExtras((prevSelectedExtras) =>
        prevSelectedExtras.includes(extraId)
          ? prevSelectedExtras.filter((id) => id !== extraId)
          : [...prevSelectedExtras, extraId]
      );
    });

    const handleSideChange = useCallback((sideId, sideCategory) => {
      // Deselect any previously selected sides from the same category
      const updatedSides = selectedSides.filter(
        (id) =>
          !data.food[0].sides.find(
            (side) =>
              side.side_name === sideCategory &&
              side.items.some((item) => item.id === id)
          )
      );

      // Check if the sideId is already in the updatedSides array
      const isSideSelected = updatedSides.includes(sideId);

      // if it's not selected, add it to the array
      if (!isSideSelected) {
        setSelectedSides([...updatedSides, sideId]);
      }
      // No need for an else block, since we're already ensuring a single selection per category
    });

    // Update function for when a radio button is selected
    const handleOptionChange = useCallback((optionId) => {
      // Check if the optionId is already in the selectedOptions array
      const isOptionSelected = selectedOptions.includes(optionId);

      // If it's not selected, add it to the array
      if (!isOptionSelected) {
        setSelectedOptions([...selectedOptions, optionId]);
      } else {
        // If it's already selected, this means we are unselecting it,
        // so we filter it out from the array
        setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      }
    });
    const handleQuantityChange = useCallback((value) => {
      setQuantity(value);
    });

    // Right before setting the item ID, log it to ensure it's not null
    const onOpenFoodDrawerDrawer = useCallback((itemID) => {
      // console.log("item id before setting state", itemID);
      onCloseMealsDrawer();
      if (itemID) {
        setTimeout(() => {
          setSelectedItemId(itemID);
          setFoodQuantityDrawer(true);
        }, 350);
      }
    });

    useEffect(() => {
      // console.log("selectedItemId after state update", selectedItemId);
      // Perform actions that depend on the updated value of selectedItemId
    }, [selectedItemId]);

    const onCloseFoodDrawerDrawer = () => {
      onOpenMealsDrawer();
      setFoodQuantityDrawer(false);
    };

    // Example function to handle adding/updating the food item in booking
    const handleAddFoodItem = useCallback(() => {
      if (!isUpdating) {
        // console.log("handleAddFoodItem selectedItemId", selectedItemId); // Add this log
        if (!selectedItemId) {
          console.error("No selected item ID");
          return; // Early return if selectedItemId is null or undefined
        }

        // Construct the food item object
        const foodItem = {
          item_id: selectedItemId, // Assuming `data` has the food item's ID
          quantity,
          variations: selectedOptions || [],
          sides: selectedSides || [],
          extras: selectedExtras || [],
        };

        // Call the context function to add/update the food item
        // Assume `userIndex` is the index of the user in the booking for whom this food item is being added/updated
        addOrUpdateFoodItem(currentIndex, foodItem, currentUserType);

        // Close the drawer and reset selections if necessary
        onCloseFoodDrawerDrawer();
        setSelectedOptions([]);
        setSelectedExtras([]);
        setSelectedSides([]);
        setQuantity(1);
        document.body.style.overflow = "auto";
      } else {
        // console.log("handleAddFoodItem selectedItemId", selectedItemId); // Add this log
        if (!selectedItemId) {
          console.error("No selected item ID");
          return; // Early return if selectedItemId is null or undefined
        }

        // Construct the food item object
        const foodItem = {
          item_id: selectedItemId, // Assuming `data` has the food item's ID
          quantity,
          variations: selectedOptions || [],
          sides: selectedSides || [],
          extras: selectedExtras || [],
        };

        // Call the context function to add/update the food item
        // Assume `userIndex` is the index of the user in the booking for whom this food item is being added/updated
        addFoodToUser(user.user_id, foodItem);

        // Close the drawer and reset selections if necessary
        onCloseFoodDrawerDrawer();
        setSelectedOptions([]);
        setSelectedExtras([]);
        setSelectedSides([]);
        setQuantity(1);
        document.body.style.overflow = "auto";
      }
    }, [
      selectedItemId, // Make sure this is included
      quantity,
      selectedOptions,
      selectedExtras,
      selectedSides,
      currentIndex,
      addOrUpdateFoodItem,
    ]);

    const displayQuantity = (
      currentUserType,
      booking,
      currentIndex,
      item,
      user,
      updateBookingData
    ) => {
      if (!isUpdating) {
        const userType =
          currentUserType === "adult" ? "booking_users" : "booking_dependents";
        const userFood = booking[userType][currentIndex]?.booking_food;
        const itemFound = userFood?.find((i) => i.item_id === item.id);

        return itemFound && itemFound.quantity >= null;
      } else {
        console.log("is updating", isUpdating);
        // console.log("item ,", item);

        // here we are updating the booking which is different from adding a new booking so we need to check if the item is already in the updateBookingData
        // if it is then we need to display the quantity of the item in the updateBookingData and not the booking
        // we here use user id to get the user from the updateBookingData
        const getUser =
          updateBookingData?.booking_users?.find(
            (userGuest) => userGuest.user_id === user.user_id
          ) ||
          updateBookingData?.booking_dependents?.find(
            (userDependent) => userDependent.dependent_id === user.user_id
          );
        const userFood = getUser?.booking_food;
        console.log("user food", userFood);
        const itemFound = userFood?.find((i) => i.item_id === item.id);
        console.log("item found", itemFound);
        return itemFound && itemFound.quantity >= null;
      }
    };
    // Function to check if an item is selected and has a quantity of 1 or more
    const isItemSelected = (
      currentUserType,
      booking,
      currentIndex,
      itemId,
      user,
      updateBookingData
    ) => {
      if (!isUpdating) {
        const userType =
          currentUserType === "adult" ? "booking_users" : "booking_dependents";
        const userFood = booking[userType]?.[currentIndex]?.booking_food;
        const itemFound = userFood?.find((i) => i.item_id === itemId);

        return itemFound && itemFound.quantity >= 1;
      } else {
        // here we are updating the booking which is different from adding a new booking so we need to check if the item is already in the updateBookingData
        // if it is then we need to display the quantity of the item in the updateBookingData and not the booking
        // we here use user id to get the user from the updateBookingData
        const getUser =
          updateBookingData?.booking_users?.find(
            (userGuest) => userGuest.user_id === user.id
          ) ||
          updateBookingData?.booking_dependents?.find(
            (userDependent) => userDependent.dependent_id === user.id
          );
        const userFood = getUser?.booking_food;
        const itemFound = userFood?.find((i) => i.item_id === itemId);
        return itemFound && itemFound.quantity >= 1;
      }
    };

    // Function to determine if the "Add Item" button should be disabled
    const isAddButtonDisabled = useCallback(() => {
      const foodItem = data.food[0];
      const hasVariations = foodItem?.variations?.length > 0;
      const sidesLength = foodItem?.sides?.length;
      const hasSides = foodItem?.sides?.length > 0;

      // If there are variations or sides, check if any have been selected
      const isVariationSelected = hasVariations && selectedOptions.length > 0;
      // is sideSelected must choose one of the sides not just one of the first side!
      const isSideSelected = hasSides && selectedSides.length > 1;

      // Disable the button if there are variations or sides that need selection
      return (
        (hasVariations && !isVariationSelected) || (hasSides && !isSideSelected)
      );
    }, [data.food, selectedOptions, selectedSides]);

    // console.log("food item user", user);
    // console.log("food is updating", isUpdating);

    function extractTextFromHTML(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    }

    return (
      <>
        <div className="w-full flex flex-col items-start justify-start ">
          {data &&
            data.food.map((item, index) => (
              <div key={index} className="kn-food-item">
                <div className="w-full h-[90px] md:h-[160px] relative rounded-[12px]">
                  <img
                    src={item?.image}
                    className="w-full h-full object-cover rounded-[12px]"
                    alt=""
                    srcSet=""
                  />

                  {displayQuantity(
                    currentUserType,
                    booking,
                    currentIndex,
                    item,
                    user,
                    updateBookingData
                  ) && (
                    <p className="w-[25px] h-[25px] rounded-full absolute right-[-5%] top-[-5%] bg-[#FCD12A] text-white flex items-center justify-center">
                      {!isUpdating
                        ? booking?.[
                            currentUserType === "adult"
                              ? "booking_users"
                              : "booking_dependents"
                          ][currentIndex]?.booking_food?.find(
                            (i) => i.item_id === item.id
                          )?.quantity
                        : updateBookingData?.booking_users
                            ?.find((userGuest) => userGuest.user_id === user.id)
                            ?.booking_food?.find((i) => i.item_id === item.id)
                            ?.quantity ||
                          updateBookingData?.booking_dependents
                            ?.find(
                              (userDependent) =>
                                userDependent.dependent_id === user.id
                            )
                            ?.booking_food?.find((i) => i.item_id === item.id)
                            ?.quantity ||
                          null}
                    </p>
                  )}
                </div>
                <div className="w-full flex flex-col items-start justify-start gap-2 self-center">
                  <h4 className="text-[#2A2A2A] text-[16px] font-semibold leading-6">
                    {item?.name[language]}
                  </h4>

                  <p className="text-[#5D5D5D] text-[14px] font-normal leading-5">
                    {extractTextFromHTML(item?.description[language])}
                  </p>

                  <div className="w-full flex items-start justify-start gap-2">
                    <p className="flex gap-2 text-[#D03C32] text-[18px] leading-6 font-bold">
                      {item?.prices?.price_after_discount}
                      <SaudiSymbol/>
                    </p>
                    {item?.prices?.price_after_discount !==
                      item?.prices?.price_before_discount && (
                      <s className="flex gap-2 text-[#878787] text-[12px] font-medium leading-4 self-center">
                        {item?.prices?.price_before_discount}
                        <SaudiSymbol/>
                      </s>
                    )}
                  </div>

                  {/* {!isFoodItemselected && ( */}
                  {!isItemSelected(
                    currentUserType,
                    booking,
                    currentIndex,
                    item.id,
                    user,
                    updateBookingData
                  ) ? (
                    <ButtonSecondary
                      onClick={() => onOpenFoodDrawerDrawer(item.id)}
                      className="my-[0.6rem]"
                    >
                      {language === "en" ? "Select" : "اختار"}
                    </ButtonSecondary>
                  ) : (
                    <div className="w-full flex items-start justify-start gap-8 mt-[1rem]  pb-4">
                      <div className="flex items-start justify-start gap-4">
                        <p className="w-[25px] h-[25px] rounded-full  bg-[#FCD12A] text-white flex items-center justify-center">
                          {!isUpdating
                            ? booking?.[
                                currentUserType === "adult"
                                  ? "booking_users"
                                  : "booking_dependents"
                              ][currentIndex]?.booking_food?.find(
                                (i) => i.item_id === item.id
                              )?.quantity || null
                            : updateBookingData?.booking_users
                                ?.find(
                                  (userGuest) => userGuest.user_id === user.id
                                )
                                ?.booking_food?.find(
                                  (i) => i.item_id === item.id
                                )?.quantity ||
                              updateBookingData?.booking_dependents
                                ?.find(
                                  (userDependent) =>
                                    userDependent.dependent_id === user.id
                                )
                                ?.booking_food?.find(
                                  (i) => i.item_id === item.id
                                )?.quantity ||
                              null}
                        </p>
                        <p className="text-black text-[16px] leading-5  font-medium self-center">
                          Item Added
                        </p>
                      </div>

                      <button
                        className="self-center"
                        onClick={() =>
                          !isUpdating
                            ? removeFoodItem(
                                currentIndex,
                                item.id,
                                currentUserType
                              )
                            : clearUserFoodItemById(user.user_id, item.id)
                        }
                      >
                        <svg
                          width="22"
                          height="20"
                          viewBox="0 0 23 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.49418 0.405396H13.5136C13.7717 0.405396 13.9964 0.405396 14.2081 0.438693C14.6204 0.504744 15.0116 0.666377 15.3502 0.91066C15.6889 1.15494 15.9656 1.4751 16.1584 1.8455C16.2583 2.03577 16.3284 2.24864 16.4105 2.49242L16.5425 2.8908C16.6336 3.21445 16.8317 3.49767 17.1045 3.69422C17.3772 3.89077 17.7086 3.98903 18.0444 3.97296H21.612C21.8486 3.97296 22.0754 4.06693 22.2427 4.23419C22.4099 4.40145 22.5039 4.62831 22.5039 4.86485C22.5039 5.1014 22.4099 5.32826 22.2427 5.49552C22.0754 5.66278 21.8486 5.75675 21.612 5.75675H1.3958C1.15925 5.75675 0.932397 5.66278 0.765135 5.49552C0.597873 5.32826 0.503906 5.1014 0.503906 4.86485C0.503906 4.62831 0.597873 4.40145 0.765135 4.23419C0.932397 4.06693 1.15925 3.97296 1.3958 3.97296H5.07039C5.38803 3.96534 5.69487 3.85615 5.94591 3.6614C6.19695 3.46664 6.37898 3.19657 6.46531 2.8908L6.5985 2.49242C6.67937 2.24864 6.74953 2.03577 6.84823 1.8455C7.04111 1.47495 7.31807 1.15471 7.65694 0.910415C7.99581 0.666123 8.38717 0.50457 8.79969 0.438693C9.01137 0.405396 9.23612 0.405396 9.49299 0.405396H9.49418ZM7.94585 3.97296C8.02935 3.80943 8.09897 3.63918 8.15396 3.46399L8.27288 3.10723C8.3811 2.78258 8.40607 2.71718 8.43104 2.66961C8.49524 2.54595 8.58752 2.43904 8.70048 2.35747C8.81344 2.2759 8.94395 2.22193 9.08153 2.19988C9.23655 2.18679 9.39223 2.18321 9.54769 2.18918H13.4601C13.8026 2.18918 13.874 2.19156 13.9263 2.20107C14.0638 2.22299 14.1942 2.27678 14.3071 2.35814C14.4201 2.4395 14.5124 2.54617 14.5768 2.66961C14.6017 2.71718 14.6267 2.78258 14.7349 3.10842L14.8539 3.46518L14.9002 3.59837C14.9466 3.72918 15.0013 3.85285 15.062 3.97296H7.94585Z"
                            fill="#D03C32"
                          />
                          <path
                            d="M4.26755 7.77856C4.25178 7.54249 4.14288 7.32235 3.9648 7.16657C3.78672 7.01079 3.55406 6.93214 3.31799 6.94791C3.08191 6.96368 2.86178 7.07258 2.706 7.25066C2.55022 7.42874 2.47157 7.6614 2.48734 7.89748L3.03912 16.1647C3.1402 17.6893 3.22226 18.9213 3.4149 19.8893C3.61588 20.8941 3.95599 21.7337 4.65999 22.3913C5.3628 23.0501 6.22377 23.3343 7.24053 23.4663C8.21804 23.5948 9.45242 23.5948 10.9817 23.5948H12.027C13.5551 23.5948 14.7907 23.5948 15.7682 23.4663C16.7838 23.3343 17.6447 23.0501 18.3487 22.3913C19.0516 21.7337 19.3917 20.8929 19.5926 19.8893C19.7853 18.9224 19.8661 17.6893 19.9684 16.1647L20.5202 7.89748C20.536 7.6614 20.4573 7.42874 20.3015 7.25066C20.1458 7.07258 19.9256 6.96368 19.6896 6.94791C19.4535 6.93214 19.2208 7.01079 19.0427 7.16657C18.8647 7.32235 18.7558 7.54249 18.74 7.77856L18.193 15.984C18.0859 17.5858 18.0098 18.7013 17.8433 19.5396C17.6804 20.3542 17.4545 20.7847 17.1298 21.0892C16.804 21.3936 16.3592 21.591 15.5363 21.698C14.6884 21.8086 13.5706 21.811 11.964 21.811H11.0436C9.43815 21.811 8.32031 21.8086 7.47123 21.698C6.64831 21.591 6.20355 21.3936 5.87772 21.0892C5.55307 20.7847 5.32712 20.3542 5.1642 19.5408C4.99772 18.7013 4.92161 17.5858 4.81458 15.9828L4.26755 7.77856Z"
                            fill="#D03C32"
                          />
                          <path
                            d="M8.44204 9.92376C8.67731 9.90018 8.91231 9.97099 9.09539 10.1206C9.27847 10.2703 9.39464 10.4865 9.41837 10.7217L10.013 16.6677C10.0304 16.8996 9.95646 17.1293 9.80697 17.3075C9.65749 17.4858 9.44427 17.5986 9.21279 17.6218C8.98131 17.6451 8.74991 17.577 8.56793 17.4321C8.38595 17.2871 8.2678 17.0768 8.23869 16.846L7.6441 10.9001C7.62052 10.6648 7.69133 10.4298 7.84096 10.2467C7.99059 10.0637 8.20679 9.94749 8.44204 9.92376ZM14.5664 9.92376C14.8014 9.94749 15.0174 10.0635 15.167 10.2463C15.3166 10.4291 15.3876 10.6638 15.3643 10.8989L14.7697 16.8448C14.7402 17.0752 14.622 17.285 14.4403 17.4296C14.2585 17.5742 14.0275 17.6422 13.7964 17.6191C13.5653 17.596 13.3523 17.4837 13.2027 17.306C13.0532 17.1283 12.9788 16.8993 12.9954 16.6677L13.59 10.7217C13.6138 10.4867 13.7298 10.2707 13.9126 10.1211C14.0954 9.97148 14.3313 9.90052 14.5664 9.92376Z"
                            fill="#D03C32"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}

          <Drawer
            onClose={onCloseFoodDrawerDrawer}
            width={550}
            closeIcon={null}
            open={foodQuantityDrawer}
            className="kn-food-quantity-drawer relative"
          >
            <div className="w-full flex items-start justify-between">
              <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
                Select Quantity
              </h3>
              <button onClick={onCloseFoodDrawerDrawer} className="self-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="none"
                  viewBox="0 0 23 23"
                >
                  <g fill="#E63232" clipPath="url(#clip0_366_2657)">
                    <path d="M12.275 11.267l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.954a.625.625 0 10.882.883l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
                    <path d="M11.267 21.281a10.015 10.015 0 110-20.03 10.015 10.015 0 010 20.03zm0-18.778a8.763 8.763 0 100 17.526 8.763 8.763 0 000-17.526z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_366_2657">
                      <path fill="#fff" d="M0 0H22.534V22.534H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>

            <div className="w-full flex flex-col items-start justify-start ">
              <div className="w-full flex flex-col items-start justify-start gap-[1.4rem] pb-[4rem]">
                <div className="w-full mt-[2rem] pb-[2rem] border-b-[6px] border-[#F6F6F6] ">
                  <QuantityInput
                    htmlFor="no-of-kids-input"
                    labelTitle={language === "en" ? " Quantity" : "الكمية"}
                    quantity={quantity}
                    labelStyles="text-[#2A2A2A] text-[22px] font-semibold leading-6"
                    onQuantityChange={handleQuantityChange}
                  />
                </div>

                {data?.food?.map((foodItem) => (
                  <>
                    {/* Handle the variations */}
                    {foodItem.variations.map((variation) => (
                      <div className="w-full pb-[2rem]" key={uuidv4()}>
                        <div className="w-full flex items-start justify-start mb-4 gap-1">
                          <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
                            Select {variation.variation_name[language]}
                          </h3>

                          <span className="self-center text-[14px] font-medium text-gray-400">
                            (Required)
                          </span>
                        </div>
                        <div className="w-full flex flex-col items-start justify-start gap-3 ">
                          {variation.items.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              className="w-full flex items-start justify-between border-b py-2 border-[#E8E8E8]"
                            >
                              <label className="size-option">
                                {item.name[language]}
                              </label>
                              <div className="flex items-start justify-start gap-2 self-center">
                                <p className="text-[#282828] text-[12px] leading-4 font-normal">
                                  {item.price > 0 &&
                                    ` (+${item.price.toFixed(2)}) ${translate(
                                      "currency"
                                    )} `}
                                </p>

                                <label>
                                  <input
                                    type="radio"
                                    name={`variation-${variation.id}`} // All radios in the same group should have the same name
                                    value={item.id}
                                    checked={selectedOptions.includes(item.id)}
                                    onChange={() => handleOptionChange(item.id)}
                                    className="self-center"
                                  />
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* handle sides */}
                    {foodItem.sides.map((side) => (
                      <div className="w-full pb-[2rem]" key={uuidv4()}>
                        <div className="w-full flex items-start justify-start mb-4 gap-1">
                          <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6 ">
                            Select {side.side_name[language]}
                          </h3>

                          <span className="self-center text-[14px] font-medium text-gray-400">
                            (Required)
                          </span>
                        </div>
                        <div className="w-full flex flex-col items-start justify-start gap-3 ">
                          {side.items.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              className="w-full flex items-start justify-between border-b py-2 border-[#E8E8E8]"
                            >
                              <label className="size-option">
                                {item.name[language]}
                              </label>
                              <div className="flex items-start justify-start gap-2 self-center">
                                <p className="text-[#282828] text-[12px] leading-4 font-normal">
                                  {item.price > 0 &&
                                    ` (+${item.price.toFixed(2)} ${translate(
                                      "currency"
                                    )} )`}
                                </p>
                                <input
                                  type="radio"
                                  name={`side-${side.id}`} // Unique name for each side group// All radios in the same group should have the same name
                                  value={item.id}
                                  checked={selectedSides.includes(item.id)}
                                  onChange={() =>
                                    handleSideChange(item.id, side.side_name)
                                  }
                                  className="self-center"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* Handle extras */}
                    {foodItem.extras.length > 0 && (
                      <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
                        Select Extras
                      </h3>
                    )}
                    {foodItem.extras.map((extra) => (
                      <div
                        key={uuidv4()}
                        className="w-full flex items-center justify-between border-b py-2 border-[#E8E8E8]"
                      >
                        <label className="size-option">
                          {extra.name[language]}
                        </label>
                        <div className="flex items-center justify-center gap-[0.5rem]">
                          <p className="text-[#282828] text-[12px] leading-4 font-normal">
                            {`(+${extra.price.toFixed(2)} ${translate(
                              "currency"
                            )})`}
                          </p>

                          <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                            <input
                              type="checkbox"
                              value={extra.id}
                              checked={selectedExtras.includes(extra.id)}
                              onChange={() => handleExtraChange(extra.id)}
                              className="kn-payment-checkbox self-center"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </>
                ))}

                <div className="w-full bg-white border-t border-[#e3e3e3] absolute bottom-0 left-0 p-[1rem] ">
                  <ButtonSecondary
                    disabled={isAddButtonDisabled()}
                    onClick={handleAddFoodItem}
                  >
                    {language === "en" ? "Add Item" : "أضف العنصر"}
                  </ButtonSecondary>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
);
