import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import axiosInstance from "../../axios/axiosInstance";
import { useOutletContext } from "react-router-dom";

const AccountRoot = ({}) => {
  const { userProfile } = useOutletContext();
  console.log("🚀 ~ AccountRoot ~ userProfile:", userProfile)
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, token } = useUser();


  const onLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <section className="w-full h-auto mb-[4rem] ">
        <div className="container mx-auto">
          <div className="account-breadcrumb ">
            <Breadcrumb location={location} />
          </div>
          <div className="w-full account-grid">
            <div className="kn-account-tabs-list w-full h-full sticky top-[105px]">
              <div className="tabsList sticky top-[7rem]">
                <h2 className="text-[#2A2A2A] text-[14px] md:text-[16px] font-semibold leading-6 py-[14px] px-[15px]">
                  {language === "en" ? "Hi," : "مرحبا"} {user?.name}
                </h2>
                <div
                  className={`w-full flex flex-col items-start justify-start gap-2`}
                >
                  <NavLink
                    to="/account/account-details"
                    className={({ isActive }) =>
                      isActive ? "tabItem tabItemActive" : "tabItem"
                    }
                  >
                    <h2 className="w-full flex items-start justify-start gap-3">
                      <svg
                        className="self-center  h-[20px] w-[25px]"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 17"
                      >
                        <path
                          stroke="#2A2A2A"
                          strokeWidth="1.514"
                          d="M10.746 1.759a.96.96 0 00-.96-.961H8.678a.961.961 0 00-.962.96c0 .438-.3.814-.708.975a7.23 7.23 0 00-.191.08c-.403.174-.88.121-1.19-.189a.961.961 0 00-1.36 0l-.782.783a.96.96 0 000 1.359c.31.31.363.787.187 1.19a6.015 6.015 0 00-.08.192c-.16.408-.535.708-.973.708a.96.96 0 00-.96.96v1.108c0 .53.43.96.96.96.438 0 .813.3.974.709l.08.191c.174.402.121.88-.188 1.19a.961.961 0 000 1.36l.783.782a.96.96 0 001.358 0c.31-.31.788-.363 1.19-.188.063.028.128.054.192.08.408.16.708.536.708.974 0 .53.43.96.961.96h1.107c.531 0 .961-.43.961-.96 0-.438.3-.813.708-.975.064-.025.129-.051.192-.079.402-.175.88-.121 1.19.188a.96.96 0 001.359 0l.783-.783a.96.96 0 000-1.358c-.31-.31-.364-.788-.189-1.19a5.25 5.25 0 00.08-.192c.16-.408.537-.708.974-.708.531 0 .961-.43.961-.961V7.818a.96.96 0 00-.96-.961c-.438 0-.814-.3-.975-.708a6.005 6.005 0 00-.08-.192c-.174-.402-.121-.88.189-1.19a.96.96 0 000-1.359l-.783-.783a.96.96 0 00-1.359 0c-.31.31-.787.364-1.19.189a5.928 5.928 0 00-.192-.08c-.408-.162-.708-.538-.708-.975z"
                        ></path>
                        <path
                          stroke="#2A2A2A"
                          strokeWidth="1.514"
                          d="M12.261 8.37a3.029 3.029 0 11-6.057 0 3.029 3.029 0 016.057 0z"
                        ></path>
                      </svg>
                      <span className="text-[#2A2A2A] text-[14px] md:text-[16px] font-medium">
                        {language === "en"
                          ? "Account Details"
                          : "إعدادات الحساب"}
                      </span>
                    </h2>
                  </NavLink>

                  <NavLink
                    to="/account/my-booking"
                    className={({ isActive }) =>
                      isActive ? "tabItem tabItemActive" : "tabItem"
                    }
                  >
                    <h2 className="w-full flex items-start justify-start gap-3">
                      <svg
                        className="self-center h-[20px] w-[25px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="17"
                        fill="none"
                        viewBox="0 0 18 17"
                      >
                        <path
                          stroke="#2A2A2A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.625 9.05c0-.92.84-1.666 1.875-1.666v-.667c0-2.666-.75-3.333-3.75-3.333h-7.5c-3 0-3.75.667-3.75 3.333v.334c1.035 0 1.875.746 1.875 1.666 0 .92-.84 1.667-1.875 1.667v.333c0 2.667.75 3.334 3.75 3.334h7.5c3 0 3.75-.667 3.75-3.334-1.035 0-1.875-.746-1.875-1.666z"
                        ></path>
                        <path
                          stroke="#2A2A2A"
                          strokeDasharray="3.33 3.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7.5 3.384v10.667"
                        ></path>
                      </svg>

                      <span className="text-[#2A2A2A] text-[14px] md:text-[16px] font-medium">
                        {language === "en" ? "My Bookings" : "حجوزاتي"}
                      </span>
                    </h2>
                  </NavLink>

                  <button
                    onClick={onLogout}
                    className="w-full flex items-start justify-start gap-3 py-[14px] px-[15px]"
                  >
                    <svg
                      className="self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 19 19"
                    >
                      <path
                        fill="#EB5757"
                        d="M10.748 5.786a.757.757 0 101.513 0v-.757a3.028 3.028 0 00-3.027-3.03H4.693a3.028 3.028 0 00-3.028 3.03v9.087a3.028 3.028 0 003.028 3.029h4.546a3.028 3.028 0 003.028-3.03v-.757a.757.757 0 10-1.514 0v.758c0 .836-.678 1.514-1.514 1.514H4.693a1.514 1.514 0 01-1.514-1.514V5.029c0-.837.678-1.515 1.514-1.515h4.54c.837 0 1.514.678 1.514 1.515v.757z"
                      ></path>
                      <path
                        fill="#EB5757"
                        d="M14.532 10.33a.757.757 0 100-1.515H6.959a.757.757 0 000 1.515h7.573z"
                      ></path>
                      <path
                        fill="#EB5757"
                        d="M13.24 7.836a.757.757 0 111.07-1.07l2.272 2.271a.757.757 0 010 1.071L14.31 12.38a.757.757 0 01-1.07-1.071l1.736-1.736-1.737-1.737z"
                      ></path>
                    </svg>
                    <span className="text-[#EB5757] text-[14px] md:text-[16px] font-medium">
                      {language === "en" ? "Logout" : "تسجيل الخروج"}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <Outlet context={{ userProfile }} />
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountRoot;
