import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";

const Breadcrumb = ({ location, titles }) => {
  const { language } = useLanguage();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const getBreadcrumbName = (path, isLast = false) => {
    if (isLast && titles && titles[language]) {
      return titles[language]; // Use the dynamic title for the last item based on the current language
    }

    switch (path) {
      case "account":
        return language === "en" ? "Account" : "حسابي";
      case "my-booking":
        return language === "en" ? "My Booking" : "حجوزاتي";
      case "booking-details":
        return language === "en" ? "Booking Details" : "تفاصيل الحجز";
      case "account-details":
        return language === "en" ? "Account Settings" : "إعدادات الحساب";
      case "activities":
        return language === "en" ? "activities" : "الأنشطة";
      // Add more cases for other paths if needed
      default:
        return path;
    }
  };

  return (
    <div className="w-full flex items-start justify-start gap-2">
      <span className="text-[#898989]">
        <Link
          className="text-[#898989] text-[15px] leading-6 tracking-[0px] font-normal"
          to="/"
        >
          {language === "en" ? "Home" : "الرئيسية"}
        </Link>
        {" > "}
      </span>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}/`;
        const isLast = index === pathnames.length - 1;
        const displayName = getBreadcrumbName(name, isLast);

        return (
          <span className="text-[#898989]" key={name}>
            {isLast ? (
              <span className="text-[#FCD12A] text-[14px] leading-6 tracking-[0px] font-medium">
                {displayName}
              </span>
            ) : (
              <Link
                className="text-[#898989] text-[14px] leading-6 tracking-[0px] font-normal"
                to={routeTo}
              >
                {displayName}
              </Link>
            )}
            {isLast ? "" : " > "}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
