import React from "react";

const QuantityInput = ({
  htmlFor,
  labelTitle,
  labelPrice,
  quantity,
  labelStyles,
  onQuantityChange,
  ageInfo,
  max,
}) => {
  const handleIncrement = () => {
    if (max !== undefined && quantity >= max) {
      return; // Prevent incrementing if quantity has reached the max value
    }
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <>
      <div className="w-full  xs:gap-[0.4rem] flex items-start justify-between gap-[3rem]">
        <label
          htmlFor={htmlFor}
          className={`text-black text-[12px] xs:text-[10px] md:text-[16px] font-normal leading-6   ${labelStyles}`}
        >
          <span className="flex flex-col item-start justify-start gap-1">
            <span> {labelTitle}</span>
            <span className="text-[12px] text-gray-500"> {ageInfo}</span>
          </span>
          <span className="self-start">-</span>
          {labelPrice && (
            <span className="font-medium leading-6 self-start">
              {" "}
              {labelPrice !== 0 ? labelPrice : "Free"}
            </span>
          )}
        </label>
        <div className="kn-quantity-input-container  w-[125px] xs:w-[85px]  md:w-[150px] lg:w-[175px] h-[45px] xs:h-[35px]">
          <button
            onClick={handleDecrement}
            className="text-[#9A9A9A] w-[3rem] text-[22px] font-medium"
          >
            -
          </button>
          <input
            className="text-black xs:w-[1rem]  w-[3rem] text-center"
            type="number"
            value={quantity}
            onChange={(e) => onQuantityChange(parseInt(e.target.value, 10))}
            name="Quantity input"
            id={htmlFor}
            max={max}
          />
          <button
            onClick={handleIncrement}
            className="text-[#FCD12A] w-[3rem] text-[22px] font-medium"
          >
            +
          </button>
        </div>
      </div>
    </>
  );
};

export default QuantityInput;
