import React from "react";

const ButtonWhite = ({
  type = "button",
  children,
  style,
  className,
  ...props
}) => {
  return (
    <>
      <button
        type={type}
        style={style}
        className={`w-auto  min-w-[120px] flex items-center justify-center gap-2 bg-white
        py-[6px] px-[14px]  md:py-[8px] md:px-[20px]  border border-black 
                            rounded-[100px] text-black font-medium leading-6 text-[12px] md:text-[16px] hover:bg-slate-200 transition-all
                            duration-200 cursor-pointer xs:min-w-[80px] xs:py-[7px] xs:px-[14px]   ${className}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default ButtonWhite;
