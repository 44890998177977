import { useEffect } from "react";
import confetti from "canvas-confetti";

const Fireworks = () => {
  const triggerFireworks = () => {
    const duration = 5 * 1000; // 5 seconds duration
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  useEffect(() => {
    // Ensure this code only runs in the browser
    triggerFireworks();
  }, []); // Empty dependency array means it runs only once after mount

  return null; // This component doesn't need to render anything
};

export default Fireworks;
