import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GuestBookingInfo from "./GuestBookingInfo";
import pic from "../../../assets/beach-access/beach-access-grid-1.webp";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { useBooking } from "../../../contexts/BookingContext";
import { BookingInfoSummary } from "./BookingInfoSummary";
import { DependentBookingInfo } from "./DependentBookingInfo";
import axiosInstance from "../../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import { useLanguage } from "../../../contexts/LanguageContext";
import { notification } from "antd";
import { useBookingTracking } from "../../../contexts/BookingTrackingContext";
import { useUser } from "../../../contexts/UserContext";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import PlusAccordion from "../../../shared/faq/PlusAccordion";
import MinusAccordion from "../../../shared/faq/MinusAccordion";
import BlurFade from "../../../components/ui/blur-fade";
import manIcon from "../../../assets/manIconGuest.svg";
import manIconActive from "../../../assets/manIconActive.svg";
import womanIcon from "../../../assets/womenIconGuest.svg";
import womanIconActive from "../../../assets/womenIconActive.svg";
import juniorIcon from "../../../assets/juniorIconGuest.svg";
import juniorIconActive from "../../../assets/juniorIconActive.svg";
import kidIcon from "../../../assets/kidIconGuest.svg";
import kidIconActive from "../../../assets/kidIconActive.svg";
import { icons } from "antd/es/image/PreviewGroup";
import { useTransition, animated } from "@react-spring/web";

const BookingInformation = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userType } = useUser();

  // Get the static page tracking info
  const { currentPage, previousPage } = useBookingTracking();

  const [api, contextHolder] = notification.useNotification();
  const { language, translate } = useLanguage();
  const { state } = location;
  const {
    booking,
    addManToBooking,
    addWomanToBooking,
    addJuniorToBooking,
    addKidToBooking,
    addBookingUser,
    addBookingDependent,
    setBooking,
    updateBookingSummary,
  } = useBooking();
  const [guest, setGuest] = useState();
  const [dependent, setDependent] = useState();
  const [activities, setActivities] = useState([]);
  const [food, setFood] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [guestPricePerPerson, setGuestPricePerPerson] = useState(null);
  const [dependentPricePerPerson, setDependentPricePerPerson] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const [unselectedMembers, setUnselectedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  // let height = 0;
  // const transitions = useTransition(
  //   booking.booking_users.map((data) => ({
  //     ...data,
  //     y: (height += 510) - 510,
  //   })),
  //   {
  //     key: (item) => item.first_name,
  //     from: { height: 0, opacity: 0 },
  //     leave: { height: 0, opacity: 0 },
  //     enter: ({ y, height }) => ({ y, height: 510, opacity: 1 }),
  //     update: ({ y, height }) => ({ y, height: 510 }),
  //   }
  // );

  // useEffect(() => {
  //   if (!isLoading) updateBookingSummary();
  // }, []);

  useEffect(() => {
    if (state) {
      setGuestPricePerPerson(state.guestPrice);
      setDependentPricePerPerson(state.dependentPrice);
    }
  }, []);

  useEffect(() => {
    const savedBooking = JSON.parse(localStorage.getItem("bookings")) || {};

    // Use a fallback key if user.id is not available
    const bookingKey = user?.id || "kona_currentBooking";

    if (savedBooking[bookingKey]) {
      setBooking(savedBooking[bookingKey]);
      setSelectedMembers(savedBooking[bookingKey].booking_users || []);
    }
  }, [user]);

  // Save the booking object to local storage on any booking changes
  useEffect(() => {
    const savedBooking = JSON.parse(localStorage.getItem("bookings")) || {};

    // Use a fallback key if user.id is not available
    const bookingKey = user?.id || "kona_currentBooking";

    // Ensure bookingKey is valid and not undefined
    if (bookingKey && bookingKey !== "undefined") {
      savedBooking[bookingKey] = booking; // Save booking object with the valid key
      localStorage.setItem("bookings", JSON.stringify(savedBooking));
    }
  }, [booking, user]);

  // wanna when this page loads for the first time to clear formErrors from local storage
  useEffect(() => {
    localStorage.removeItem("formErrors");
  }, []);
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  useEffect(() => {
    if (booking) {
      const newGuestCount = booking.booking_users.length;
      const newDependentCount = booking.booking_dependents.length;

      // Only update state if counts have actually changed
      if (guest !== newGuestCount) {
        setGuest(newGuestCount);
      }
      if (dependent !== newDependentCount) {
        setDependent(newDependentCount);
      }
    }
  }, [booking]);

  // fetch activities
  useEffect(() => {
    if (booking.booking_date) {
      const formattedDate = new Date(booking.booking_date)
        .toISOString()
        .split("T")[0];

      const getActivities = async () => {
        setIsLoading(true);
        try {
          const activitiesData = await axiosInstance.get(
            `/booking/pre-requests/data/activities-list?date=${formattedDate}`
          );
          if (activitiesData.status === 200) {
            setActivities(activitiesData.data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          document.body.style.overflow = "auto";
        }
      };

      getActivities();
    }
  }, [booking.booking_date]);

  // fetch Food
  useEffect(() => {
    if (booking.booking_date) {
      const formattedDate = new Date(booking.booking_date)
        .toISOString()
        .split("T")[0];

      const getFood = async () => {
        setIsLoading(true);
        try {
          const foodList = await axiosInstance.get(
            `/booking/pre-requests/data/food-category-list?date=${formattedDate}`
          );
          if (foodList.status === 200) {
            setFood(foodList.data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          document.body.style.overflow = "auto";
        }
      };

      getFood();
    }
  }, [booking.booking_date]);

  useEffect(() => {
    if (booking && booking.booking_users) {
      setSelectedMembers(booking.booking_users); // Populate selected members from booking object
    }
  }, [booking]);

  const handleBack = () => {
    if (previousPage) {
      navigate(previousPage);
    } else {
      navigate(-1);
    }
  };

  const proceedToPaymentHandler = async () => {
    // validate booking object data
    try {
      // wanna add language as query param to the request
      const response = await axiosInstance.post(
        `/booking/validate-users?lang=${language}`,
        {
          booking_date: booking?.booking_date,
          booking_users: booking?.booking_users,
          booking_dependents: booking?.booking_dependents,
        }
      );
      if (response.status === 200) {
        navigate("/payment");
      }
    } catch (error) {
      console.error(error);

      setFormErrors(error?.response?.data?.errors || {});

      // set form errors at local storage
      localStorage.setItem(
        "formErrors",
        JSON.stringify(error?.response?.data?.errors)
      );

      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
  const [expanded, setExpanded] = useState([]); // Start with an empty array if no items are expanded

  const handleChange = (expandedItems) => {
    setExpanded(expandedItems); // Update the expanded state with the selected AccordionItem uuid
  };

  const [selectedGuestTypes, setSelectedGuestTypes] = useState([]);

  const GUEST_OPTIONS = [
    {
      id: 1,
      name: "Man",
      gender: "male",
      memberType: "daypass",
      quantity: 1,
      icon: manIcon,
      activeIcon: manIconActive,
    },

    {
      id: 2,
      name: "Woman",
      gender: "female",
      memberType: "daypass",
      quantity: 1,
      icon: womanIcon,
      activeIcon: womanIconActive,
    },
    {
      id: 3,
      name: "Junior",
      gender: null,
      memberType: "junior",
      quantity: 1,
      icon: juniorIcon,
      activeIcon: juniorIconActive,
    },
    {
      id: 4,
      name: "Kid",
      gender: null,
      memberType: "kid",
      quantity: 1,
      icon: kidIcon,
      activeIcon: kidIconActive,
    },
  ];

  const handleGuestTypeSelect = (type) => {
    setSelectedGuestTypes((prevSelected) =>
      prevSelected.includes(type)
        ? prevSelected.filter((t) => t !== type)
        : [...prevSelected, type]
    );
  };


  const handleAddGuest = () => {
    selectedGuestTypes.forEach((type) => {
      if (type === "Man") {
        console.log("Adding a Man");
        addManToBooking();
      } else if (type === "Woman") {
        console.log("Adding a Woman");
        addWomanToBooking();
      } else if (type === "Junior") {
        console.log("Adding a Junior");
        addJuniorToBooking();
      } else if (type === "Kid") {
        console.log("Adding a Kid");
        addKidToBooking();
      }
    });
    openNotificationWithIcon(
      "success",
      language === "en" ? "Added Successfully" : "تم الاضافه بنجاح"
    );

    // Reset the selection after adding guests
    setSelectedGuestTypes([]);
  };

  return (
    <>
      {contextHolder}

      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="container mx-auto">
            <button
              onClick={handleBack}
              className="flex items-start justify-start gap-[16px] my-[0.5rem] lg:my-[2rem]"
            >
              <svg
                className="self-center rtl:rotate-180 xs:w-[18px] xs:h-[18px]"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
                viewBox="0 0 25 24"
              >
                <path
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
                ></path>
              </svg>

              <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
                {language === "en" ? "Booking Information" : "معلومات الحجز"}
              </span>
            </button>

            <div className="kn-booking-info-grid xs:my-[14px] my-[40px]">
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                {booking.booking_users.map((user, index) => (
                  <GuestBookingInfo
                    user={user}
                    activities={activities}
                    food={food}
                    index={index}
                    date={booking?.booking_date}
                    key={index}
                    type="adult"
                    errors={formErrors}
                  />
                ))}

                {/* 
                <div className="list" style={{ height }}>
                  {transitions((style, item, t, index) => {
                    console.log(item);
                    return (
                      <animated.div
                        className="card"
                        style={{
                          zIndex: booking.booking_users.length - index,
                          ...style,
                        }}
                      >
                        <GuestBookingInfo
                          user={item}
                          activities={activities}
                          food={food}
                          index={index}
                          date={booking?.booking_date}
                          key={index}
                          type="adult"
                          errors={formErrors}
                        />
                      </animated.div>
                    );
                  })}
                </div>
                 */}

                {booking.booking_dependents.map((user, index) => (
                  <DependentBookingInfo
                    user={user}
                    activities={activities}
                    food={food}
                    date={booking?.booking_date}
                    index={index}
                    key={index}
                    type="dependent"
                    errors={formErrors}
                  />
                ))}

                {user?.user_type !== "athlete" && (
                  <div className=" w-full h-auto ">
                    <div className="kn-guests-grid p-[24px] xs:p-[12px]">
                      <Accordion
                        // Expand the "Payment Summary" accordion by default
                        onChange={handleChange} // Set the handler that updates the state
                        allowZeroExpanded
                      >
                        <AccordionItem uuid={"acc-one"}>
                          <BlurFade yOffset={0} delay={0.13} inView>
                            <AccordionItemHeading className="">
                              <AccordionItemButton className="w-full h-[28px] flex items-center justify-between">
                                <h3 className="text-[#2A2A2A] text-[14px] md:text-[16px]  font-medium leading-7 ">
                                  {language === "en"
                                    ? "Add Another Guest "
                                    : " إضافة ضيف آخر "}
                                </h3>{" "}
                                <AccordionItemState>
                                  {({ expanded }) => (
                                    <div className="flex items-center">
                                      {expanded ? (
                                        <MinusAccordion />
                                      ) : (
                                        <PlusAccordion />
                                      )}
                                    </div>
                                  )}
                                </AccordionItemState>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          </BlurFade>
                          <AccordionItemPanel className="w-full mt-[24px] ">
                            <ul className="w-full flex items-start justify-start gap-6 flex-wrap mb-8">
                              {GUEST_OPTIONS.map((member) => (
                                <li
                                  className="flex flex-col items-center justify-center gap-2"
                                  key={member.id}
                                >
                                  <input
                                    type="checkbox"
                                    id={`member-${member.id}`}
                                    value={member.name}
                                    className="hidden peer"
                                    onChange={() =>
                                      handleGuestTypeSelect(member.name)
                                    }
                                    checked={selectedGuestTypes.includes(
                                      member.name
                                    )}
                                  />
                                  <label
                                    htmlFor={`member-${member.id}`}
                                    className={`group w-[62px] h-[62px] border-2 ${
                                      selectedGuestTypes.includes(member.name)
                                        ? "border-[#FCD12A] text-[#FCD12A] bg-[#FFF7D9]"
                                        : "border-[#A1A1A1] text-[#A1A1A1] bg-[#B4B4B426] hover:bg-gray-50"
                                    } rounded-full flex items-center justify-center`}
                                  >
                                    <div className="block text-center">
                                      <div
                                        className={`w-full h-full flex items-center justify-center uppercase text-[24px] font-medium  `}
                                      >
                                        {selectedGuestTypes.includes(
                                          member.name
                                        ) ? (
                                          <img
                                            className="w-[35px] h-[35px]"
                                            src={member.activeIcon}
                                            alt={member.name}
                                          />
                                        ) : (
                                          <img
                                            className="w-[35px] h-[35px]"
                                            src={member.icon}
                                            alt={member.name}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </label>
                                  <p
                                    className={`text-[16px] font-normal self-center ${
                                      selectedGuestTypes.includes(member.name)
                                        ? "text-[#FCD12A] font-semibold"
                                        : "group-hover:text-gray-50 text-[#292D32]"
                                    }`}
                                  >
                                    {member.name.split(" ")[0]}
                                  </p>
                                </li>
                              ))}
                            </ul>

                            <ButtonSecondary
                              // if selectedGuestTypes is empty or its length is zero
                              disabled={selectedGuestTypes.length === 0}
                              onClick={handleAddGuest}
                            >
                              Add Guest
                            </ButtonSecondary>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full h-full">
                <div className="payment-summary-container">
                  <BookingInfoSummary
                    data={{
                      activity_image: pic,
                      activity_name: "Aqua Park Ticket",
                    }}
                  />
                </div>
              </div>

              {/* Procceed */}
              <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
                <ButtonSecondary
                  onClick={proceedToPaymentHandler}
                  className={`w-full xs:w-[200px] md:w-[250px] lg:w-[300px]`}
                >
                  {language === "en" ? "Proceed To Payment" : "المتابعة للدفع"}
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default BookingInformation;
