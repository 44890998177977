import React, { useState, useEffect } from "react";
import Input from "../../../shared/inputs/Input";
import { useBooking } from "../../../contexts/BookingContext";
import { useLanguage } from "../../../contexts/LanguageContext";

import JuniorIcon from "../../icons/JuniorIcon";
import KidIcon from "../../icons/KidIcon";

const DependentForm = React.memo(({ user, index, type, errors }) => {
  const { language } = useLanguage();
  const {
    updateBookingDependent,
    booking,
    removeBookingDependent,
    updateBookingSummary,
  } = useBooking();

  // Initialize states for dependent details
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");

  // Effect to initialize field values from `booking_dependents` based on `index`
  useEffect(() => {
    setFirstName(booking?.booking_dependents[index]?.first_name || "");
    setLastName(booking?.booking_dependents[index]?.last_name || "");
    setDateOfBirth(booking?.booking_dependents[index]?.birthdate || "");
  }, [booking.booking_dependents, index]);

  // Combine first and last name for display
  const fullName = `${firstName} ${lastName}`.trim();

  // Effect to handle changes in the errors prop
  useEffect(() => {
    if (errors) {
      setFirstNameError(!!errors?.[`booking_dependents.${index}.first_name`]);
      setFirstNameErrorMessage(
        errors?.[`booking_dependents.${index}.first_name`]?.[0] || ""
      );

      setLastNameError(!!errors?.[`booking_dependents.${index}.last_name`]);
      setLastNameErrorMessage(
        errors?.[`booking_dependents.${index}.last_name`]?.[0] || ""
      );

      setDateOfBirthError(!!errors?.[`booking_dependents.${index}.birthdate`]);
      setDateOfBirthErrorMessage(
        errors?.[`booking_dependents.${index}.birthdate`]?.[0] || ""
      );
    }
  }, [errors, index]);

  // Function to update field data
  const updateFieldData = (field, value) => {
    const updatedDependent = {
      ...booking.booking_dependents[index],
      [field]: value,
    };
    updateBookingDependent(index, updatedDependent);
  };

  // Handle first name validation on blur
  const handleFirstNameBlur = () => {
    if (!firstName) {
      setFirstNameError(true);
      setFirstNameErrorMessage("First name is required");
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");
      updateFieldData("first_name", firstName);
    }
    // updateBookingSummary();
  };

  // Handle last name validation on blur
  const handleLastNameBlur = () => {
    if (!lastName) {
      setLastNameError(true);
      setLastNameErrorMessage("Last name is required");
    } else {
      setLastNameError(false);
      setLastNameErrorMessage("");
      updateFieldData("last_name", lastName);
    }
    updateBookingSummary();
  };

  // Handle date of birth validation on blur
  const handleDateOfBirthBlur = () => {
    if (!dateOfBirth) {
      setDateOfBirthError(true);
      setDateOfBirthErrorMessage("Date of birth is required");
    } else {
      setDateOfBirthError(false);
      setDateOfBirthErrorMessage("");
      updateFieldData("birthdate", dateOfBirth);
    }
    // updateBookingSummary();
  };
  console.log("🚀 ~ DependentForm ~ user:", user);

  // Filter booking_dependents by member_type
  const filteredDependents = booking.booking_dependents.filter(
    (dependent) => dependent.member_type === user.member_type
  );

  // Calculate display index based on filtered list
  const displayIndex =
    filteredDependents.findIndex(
      (dependent) => dependent === booking.booking_dependents[index]
    ) + 1;
  // wanna keep index of juniors and kids separate
  return (
    <div className="kn-booking-info">
      <div className="w-full flex items-start justify-between mb-[2rem]">
        <div className="w-auto flex items-center justify-center bg-[#EBEBEB] py-[8px] px-[16px] rounded-[8px]">
          <div className="flex items-center justify-center gap-2">
            <span>
              {user.member_type === "junior" ? <JuniorIcon /> : <KidIcon />}
            </span>
            <p className="text-[#2A2A2A] text-[14px] font-medium leading-none mt-[2px]">
              {user?.first_name && user?.last_name
                ? user.first_name + " " + user.last_name
                : user.member_type === "junior"
                ? `Junior ${displayIndex}`
                : `Kid ${displayIndex}`}
            </p>
          </div>
        </div>
        <button
          className="self-center"
          onClick={() => removeBookingDependent(index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            fill="none"
            viewBox="0 0 23 24"
          >
            <g fill="#E63232">
              <path d="M12.275 11.879l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.955a.626.626 0 10.882.882l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
              <path d="M11.267 21.894a10.016 10.016 0 110-20.032 10.016 10.016 0 010 20.032zm0-18.779a8.763 8.763 0 100 17.527 8.763 8.763 0 000-17.527z"></path>
            </g>
          </svg>
        </button>
      </div>
      <form className="w-full h-auto mt-[2rem]">
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="first-name-input"
            type="text"
            name="firstnameInput"
            placeholder={
              language === "en" ? "Enter your first name" : "أدخل اسمك الأول"
            }
            label={language === "en" ? "First Name" : "الاسم الأول"}
            value={firstName}
            onChange={(e) => {
              const value = e.target.value;
              setFirstName(value);
              // updateFieldData("first_name", value); // Use `value` instead of `firstName`
            }}
            error={firstNameError}
            errorMessage={firstNameErrorMessage}
            redStar={true}
            onBlur={handleFirstNameBlur}
          />
          <Input
            ID="last-name-input"
            type="text"
            name="lastnameInput"
            placeholder={
              language === "en" ? "Enter your last name" : "أدخل اسمك الأخير"
            }
            label={language === "en" ? "Last Name" : "الاسم الأخير"}
            value={lastName}
            onChange={(e) => {
              const value = e.target.value;
              setLastName(value);
              // updateFieldData("last_name", value); // Use `value` instead of `lastName`
            }}
            error={lastNameError}
            errorMessage={lastNameErrorMessage}
            redStar={true}
            onBlur={handleLastNameBlur}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="date-input"
            type="date"
            name="dateInput"
            placeholder={
              language === "en"
                ? "Enter your date of birth"
                : "أدخل تاريخ ميلادك"
            }
            label={language === "en" ? "Date of Birth" : "تاريخ الميلاد"}
            value={dateOfBirth}
            onChange={(e) => {
              setDateOfBirth(e.target.value);
              // updateFieldData("birthdate", e.target.value); // Use `value` instead of `dateOfBirth`
            }}
            error={dateOfBirthError}
            errorMessage={dateOfBirthErrorMessage}
            redStar={true}
            onBlur={handleDateOfBirthBlur}
          />
        </div>
      </form>
    </div>
  );
});

export default DependentForm;
