import React, { useEffect } from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { useBooking } from "../../contexts/BookingContext";

// Payment Method Selection Component
const PaymentMethodSelection = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  totalPaymentPrice,
}) => {
  console.log("🚀 ~ paymentMethods:", paymentMethods);
  const { language } = useLanguage();
  const { setPaymentMethod } = useBooking();

  // Set the default payment method based on total payment price
  useEffect(() => {
    if (
      totalPaymentPrice === 0 &&
      selectedPaymentMethod !== "Membership Credit"
    ) {
      setSelectedPaymentMethod("Membership Credit");
      setPaymentMethod("Membership Credit");
    } else if (totalPaymentPrice > 0 && !selectedPaymentMethod) {
      // Dynamically select the first available method if not already selected
      setSelectedPaymentMethod(paymentMethods[0]?.value);
      setPaymentMethod(paymentMethods[0]?.value);
    }
  }, [
    totalPaymentPrice,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setPaymentMethod,
    paymentMethods,
  ]);

  // Handle payment method selection change (only if selection is enabled)
  const handlePaymentMethodSelection = (method) => {
    setSelectedPaymentMethod(method); // Update local state
    setPaymentMethod(method); // Update the booking context
  };

  // Get the appropriate icon based on the selected payment method
  const getPaymentIcon = (method) => {
    return selectedPaymentMethod === method.value
      ? method.activeIcon
      : method.icon;
  };

  return (
    <div className="w-full flex flex-col items-start justify-start gap-4">
      <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6">
        {language === "en" ? "Select Payment Method" : "اختر طريقة الدفع"}
      </h3>
      <div className="w-full flex flex-col items-start justify-start gap-4">
        {paymentMethods.map((type, index) => (
          <div
            key={index}
            className={`kn-payment-type ${
              selectedPaymentMethod === type.value
                ? "kn-payment-type-active"
                : ""
            }`}
          >
            <label className={`kn-payment-type-radio `}>
              <div className="flex items-start justify-start gap-3">
                {/* Display payment method icon */}
                <img
                  className="self-center"
                  src={getPaymentIcon(type)}
                  alt={`${type.value} icon`}
                />
                <span className="self-center">{type?.name[language]}</span>
              </div>
              <input
                className="self-center"
                type="radio"
                name={`paymentType${type.id}`}
                value={type.value}
                checked={selectedPaymentMethod === type.value}
                onChange={() => handlePaymentMethodSelection(type.value)}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodSelection;
