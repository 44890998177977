import * as React from "react";
import { CheckIcon, ChevronsUpDown, ChevronDown } from "lucide-react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Input } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area";
import { cn } from "../../lib/utils";

const PhoneInput = React.forwardRef(
  (
    {
      className,
      error,
      errorMessage,
      onChange,
      label,
      id,
      redStar = true,
      labelMargin,
      ...props
    },
    ref
  ) => {
    // Generate a unique ID if not provided
    const inputId = id;

    return (
      <div className="kn-phone-shadcn grid w-full  items-center ">
        {label && (
          <label
            className="relative inline-block xs:mb-3 mb-4 text-black font-semibold leading-4 xs:text-[14px] text-[16px]"
            htmlFor={inputId}
          >
            {label}
            {redStar && (
              <sup className="absolute top-[-25%]  text-[#E63232] font-semibold leading-4 text-[16px]">
                *
              </sup>
            )}
          </label>
        )}
        <RPNInput.default
          id={inputId}
          ref={ref}
          className={cn("flex ", className)}
          flagComponent={FlagComponent}
          countrySelectComponent={CountrySelect}
          inputComponent={InputComponent}
          smartCaret={false}
          defaultCountry="SA" // Set default country to Saudi Arabia
          /**
           * Handles the onChange event.
           *
           * react-phone-number-input might trigger the onChange event as undefined
           * when a valid phone number is not entered. To prevent this,
           * the value is coerced to an empty string.
           *
           * @param {E164Number | undefined} value - The entered value
           */
          onChange={(value) => onChange?.(value || "")}
          {...props}
        />

        {error && errorMessage && (
          <div className="w-full mt-2 flex items-start justify-start gap-2">
            <svg
              className="self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="none"
              viewBox="0 0 9 9"
            >
              <rect width="9" height="9" fill="#C81E1E" rx="4.5"></rect>
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="0.692"
                d="M6.115 2.86L3 5.975M3 2.86l3.115 3.115"
              ></path>
            </svg>
            <span className="text-[#D03C32] text-[14px] font-normal leading-5 tracking-[0.1px]">
              {errorMessage}
            </span>
          </div>
        )}
      </div>
    );
  }
);
PhoneInput.displayName = "PhoneInput";

const InputComponent = React.forwardRef(({ className, ...props }, ref) => (
  <Input
    className={cn("rounded-e-lg rounded-s-none", className)}
    {...props}
    ref={ref}
  />
));
InputComponent.displayName = "InputComponent";

const CountrySelect = ({
  disabled,
  value: selectedCountry,
  options: countryList,
  onChange,
}) => {
  const countryCallingCode = selectedCountry
    ? RPNInput.getCountryCallingCode(selectedCountry)
    : "+";
 
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          className="flex gap-1 rounded-e-none rounded-s-lg country-flag-command  px-3 focus:z-10 h-[48px] py-0 border border-r-0"
          disabled={disabled}
        >
          <FlagComponent
            className=""
            country={selectedCountry}
            countryName={selectedCountry}
          />
          <span className="text-sm text-foreground/50">{`+${
            countryCallingCode || ""
          }`}</span>
          <ChevronDown
            className={cn(
              "-mr-2 size-2 opacity-50 ",
              disabled ? "hidden" : "opacity-100"
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px]  p-0">
        <Command>
          <CommandInput placeholder="Search country..." />
          <CommandList>
            <ScrollArea className="h-72">
              <CommandEmpty>No country found.</CommandEmpty>
              <CommandGroup>
                {countryList.map(({ value, label }) =>
                  value ? (
                    <CountrySelectOption
                      key={value}
                      country={value}
                      countryName={label}
                      selectedCountry={selectedCountry}
                      onChange={onChange}
                    />
                  ) : null
                )}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const CountrySelectOption = ({
  country,
  countryName,
  selectedCountry,
  onChange,
}) => {
  return (
    <CommandItem className="gap-2" onSelect={() => onChange(country)}>
      <FlagComponent country={country} countryName={countryName} />
      <span className="flex-1 text-sm">{countryName}</span>
      <span className="text-sm text-foreground/50">{`+${RPNInput.getCountryCallingCode(
        country
      )}`}</span>
      <CheckIcon
        className={`ml-auto size-4 ${
          country === selectedCountry ? "opacity-100" : "opacity-0"
        }`}
      />
    </CommandItem>
  );
};

const FlagComponent = ({ country, countryName }) => {
  const Flag = flags[country];

  return (
    <span className="bg-red-200 flex h-4 w-6 overflow-hidden rounded-sm bg-foreground/20 ">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};

export { PhoneInput };
