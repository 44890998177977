import { useUser } from '../../contexts/UserContext'; // Adjust the path as necessary
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useUser();

    if (!isLoggedIn()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after logging in, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
