import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { UserProvider } from "./contexts/UserContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { BookingProvider } from "./contexts/BookingContext";
import { UpdateBookingProvider } from "./contexts/UpdateBookingContext";
import { TicketProvider } from "./contexts/TicketContext";
import * as Sentry from "@sentry/react";

console.log("Before Sentry initialized");
console.log("SENTRY_DSN", process.env.REACT_APP_SENTRY_DSN);
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
console.log("Sentry initialized");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserProvider>
    <BookingProvider>
      <TicketProvider>
        <UpdateBookingProvider>
          <LanguageProvider>
            <ConfigProvider
              theme={{
                Menu: {
                  // itemHoverColor: 'red',
                  // itemHoverBg: 'blue',
                  // itemColor: 'green',
                },
              }}
            >
              <App />
            </ConfigProvider>
          </LanguageProvider>
        </UpdateBookingProvider>
      </TicketProvider>
    </BookingProvider>
  </UserProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
