import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  if (typeof twMerge === "function") {
    return twMerge(clsx(inputs));
  } else {
    // Fallback logic or error handling
    return clsx(inputs);
  }
}