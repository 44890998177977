import React, { useEffect, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import Error from "../error/Error";
const TokenVerfication = () => {
  const { language } = useLanguage();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bookingIdFromUrl = searchParams.get("booking_id");
  console.log("🚀 ~ TokenVerfication ~ bookingId:", bookingIdFromUrl);
  console.log("🚀 ~ TokenVerfication ~ location:", location);
  const { complete_data_token } = useParams(); // This matches the parameter name in the route
  console.log(
    "🚀 ~ TokenVerfication ~ complete_data_token:",
    complete_data_token
  );
  // store the complete_data_token in the local storage

  useEffect(() => {
    if (complete_data_token) {
      localStorage.setItem("token", complete_data_token);
    }
  }, [complete_data_token]);
  useEffect(() => {
    const verfiyToken = async () => {
      try {
        const response = await axiosInstance.post(
          "/sports/check-complete-data-token",
          {
            token: complete_data_token,
          }
        );
        if (response.data.status === "success") {
          setError(false);
        }
      } catch (error) {
        setError(true);
        // navigate("/page-not-found");
      }
    };
    if (complete_data_token) {
      verfiyToken();
    }
  }, [complete_data_token]); // ✅ Add dependencies

  return <>{error ? <Error /> : null}</>;
};

export default TokenVerfication;
