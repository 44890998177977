import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
const Input = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  className,
  label,
  required,
  error,
  errorMessage,
  ID,
  info,
  infoLink,
  disabled,
  infoPosition = true,
  redStar = false,
  onBlur,
  max,
  min,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  // Determines the type of the input field
  const inputType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  return (
    <>
      <div className="w-full flex flex-col items-start justify-start xs:mb-4 mb-8">
        {label && (
          <label
            htmlFor={ID}
            className="relative inline-block xs:mb-3 mb-4 text-black font-semibold leading-4 xs:text-[14px] text-[16px] "
          >
            {label}{" "}
            {redStar && (
              <sup className="absolute top-[-25%]  text-[#E63232] font-semibold leading-4 text-[16px]">
                *
              </sup>
            )}
          </label>
        )}
        <div className="w-full relative">
          <input
            id={ID}
            type={inputType}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`kn-inputfield ${
              error ? "kn-inputField-error" : ""
            } ${className} placeholder:xs:text-[12px] disabled:cursor-not-allowed disabled:opacity-70 disabled:bg-[#F0F0F0] disabled:text-[#565656] disabled:hover:border-transparent`}
            label={label}
            disabled={disabled}
            required={required}
            onBlur={onBlur}
            max={max}
            min={min}
          />
          {type === "password" && (
            <button
              type="button"
              onClick={toggleShowPassword}
              className="absolute top-1/2 ltr:right-4 rtl:left-4 transform -translate-y-1/2 cursor-pointer"
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 556 556"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="66.667"
                    d="M46.336 46.334l463.333 463.333M155.515 155.741c-70.611 48.017-109.18 122.26-109.18 122.26s84.243 162.166 231.668 162.166c47.498 0 88.436-16.833 122.113-39.652m-145.28-283.332a198.977 198.977 0 0123.167-1.349c147.423 0 231.666 162.167 231.666 162.167s-16.026 30.851-46.333 65.642"
                  ></path>
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="66.667"
                    d="M324.333 329.805c-12.297 11.004-28.532 17.697-46.333 17.697-38.385 0-69.5-31.115-69.5-69.5 0-19.082 7.69-36.367 20.139-48.926"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 556 556"
                >
                  <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="66.667"
                    clipPath="url(#clip0_463_78677)"
                  >
                    <path d="M23.164 277.999S115.831 92.666 277.997 92.666c162.167 0 254.834 185.333 254.834 185.333M23.164 278s92.667 185.333 254.833 185.333C440.164 463.333 532.831 278 532.831 278"></path>
                    <path d="M278 347.5c38.384 0 69.5-31.116 69.5-69.5s-31.116-69.5-69.5-69.5-69.5 31.116-69.5 69.5 31.116 69.5 69.5 69.5z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_463_78677">
                      <path fill="#fff" d="M0 0H556V556H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          )}
        </div>
        {error && errorMessage && (
          <div className="w-full mt-2 flex items-start justify-start gap-2">
            <svg
              className="self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="none"
              viewBox="0 0 9 9"
            >
              <rect width="9" height="9" fill="#C81E1E" rx="4.5"></rect>
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="0.692"
                d="M6.115 2.86L3 5.975M3 2.86l3.115 3.115"
              ></path>
            </svg>
            <span className="text-[#D03C32] text-[14px] font-normal leading-5 tracking-[0.1px]">
              {errorMessage}
            </span>
          </div>
        )}

        {info && (
          <Link
            to={infoLink}
            className="kn-info"
            style={{
              textAlign: infoPosition ? "left" : "right",
            }}
          >
            {info}
          </Link>
        )}
      </div>
    </>
  );
};

export default Input;
