import { useUser } from '../../contexts/UserContext'; // Adjust the path as necessary
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
    const { isLoggedIn } = useUser();

    if (isLoggedIn()) {
        // If the user is logged in, redirect them to the account page
        return <Navigate to="/account" />;
    }

    return children;
};

export default PublicRoute;