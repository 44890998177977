import React from "react";

const PlusAccordion = () => {
  return (
    <div className="w-[28px] h-[28px] bg-[#FCD12A] rounded-[8px] flex items-center justify-center p-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        fill="none"
        viewBox="0 0 13 13"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.8"
          d="M1.2 6.734h5.4m5.4 0H6.6m0 0V1.1v10.8"
        ></path>
      </svg>
    </div>
  );
};

export default PlusAccordion;
