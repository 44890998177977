import React from "react";
import juniorIcon from "../../assets/juniorIconGuest.svg";
import kidIcon from "../../assets/kidIconGuest.svg";
import manIcon from "../../assets/manIconGuest.svg";
import womanIcon from "../../assets/womenIconGuest.svg";

const SplitPaymentUserSelection = ({
  booking,
  selectedUsersForPayment,
  handleUserCheckboxToggle,
}) => {
  // Determine if the user is a junior or a kid
  const isJuniorOrKid = (user) =>
    user.member_type === "junior" || user.member_type === "kid";

  // if there is only one user at booking users then disable split payment
  const isSingleUser = booking?.booking_users?.length === 1;
  // Ensure the first user is always selected and disabled
  const isFirstUser = (index) => index === 0;

  return (
    <div className="w-full flex flex-col items-start justify-start gap-4">
      <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6">
        Who are you paying for?
      </h3>

      {booking?.booking_users?.map((user, index) => (
        <div
          className={`kn-payment-user-checkbox ${
            isJuniorOrKid(user) || isFirstUser(index)
              ? "kn-payment-user-checkbox-disabled"
              : ""
          }`}
          key={user.user_id || index} // Use user_id for members, and index for guests as key
        >
          <label className="kn-custom-checkbox w-full flex flex-row items-start justify-between cursor-pointer">
            <span className="flex items-center justify-start gap-2">
              {user.gender === "male" ? (
                <img className="w-[18px] h-[18px]" src={manIcon} alt="junior" />
              ) : (
                <img className="w-[18px] h-[18px]" src={womanIcon} alt="kid" />
              )}

              <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                {user.first_name} {user.last_name}{" "}
                {user.gender === "male" ? "( Man )" : "( Woman )"}
              </span>
            </span>
            <input
              className="kn-payment-checkbox self-center"
              type="checkbox"
              checked={
                selectedUsersForPayment.includes(index) || isFirstUser(index) // First user is always selected
              }
              onChange={(e) =>
                handleUserCheckboxToggle(index, e.target.checked)
              }
              disabled={isFirstUser(index)} // Disable the first user checkbox
            />
          </label>
        </div>
      ))}

      {/* Handle dependents always checked */}
      {booking?.booking_dependents?.map((dependent, index) => (
        <div
          className="kn-payment-user-checkbox kn-payment-user-checkbox-disabled"
          key={dependent.user_id || index} // Use user_id for dependents, and index for guests as key
        >
          <label className="kn-custom-checkbox w-full flex flex-row items-start justify-between cursor-pointer">
            <span className="flex items-center justify-start gap-2">
              {dependent.member_type === "junior" ? (
                <img
                  className="w-[18px] h-[18px]"
                  src={juniorIcon}
                  alt="junior"
                />
              ) : (
                <img className="w-[18px] h-[18px]" src={kidIcon} alt="kid" />
              )}
              <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                {dependent.first_name} {dependent.last_name} ({" "}
                {dependent.member_type} )
              </span>
            </span>
            <input
              className="kn-payment-checkbox self-center"
              type="checkbox"
              checked={true} // Dependents are always selected
              disabled={true} // Always disabled for dependents
            />
          </label>
        </div>
      ))}
    </div>
  );
};

export default SplitPaymentUserSelection;
