import React from "react";

const ButtonSecondary = ({
  type = "button",
  style,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      style={style}
      disabled={disabled}
      className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-[#FCD12A]
      py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-white
            font-medium leading-6 text-[12px] md:text-[16px] hover:bg-[#e7c22e] transition-all disabled:bg-[#FFE993]
             disabled:text-white disabled:cursor-not-allowed duration-200 cursor-pointer
             xs:min-w-[80px] xs:py-[7px] xs:px-[14px]   ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonSecondary;
